.hero-slider {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .slide-info {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        color: $white;
        @media (max-width: $mobile) {
            top: unset;
            transform: unset;
            bottom: 10%;
        }
        .super-hero,
        .hero-btn {
            font-weight: 600;
            text-transform: uppercase;
            background-color: rgba(0, 0, 0, 0.2);
        }
        .hero-btn {
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: -7px;
                width: 100%;
                height: 1px;
                background-color: $white;
            }
        }
        .hero-title {
            margin: 25px 0 40px;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
    .swiper-slide img {
        margin: 0;
        width: -webkit-fill-available;
    }
    .hero-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
        max-width: 22px;
        @media (max-width: $mobile) {
            max-width: 15px;
        }
        &.hero-button-prev {
            left: 2%;
        }
        &.hero-button-next {
            right: 2%;
        }
    }
}
