@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Thin.eot");
  src: url("../../../fonts/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Thin.woff2") format("woff2"), url("../../../fonts/Roboto-Thin.woff") format("woff"), url("../../../fonts/Roboto-Thin.ttf") format("truetype"), url("../../../fonts/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Light.eot");
  src: url("../../../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Light.woff2") format("woff2"), url("../../../fonts/Roboto-Light.woff") format("woff"), url("../../../fonts/Roboto-Light.ttf") format("truetype"), url("../../../fonts/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Regular.eot");
  src: url("../../../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Regular.woff2") format("woff2"), url("../../../fonts/Roboto-Regular.woff") format("woff"), url("../../../fonts/Roboto-Regular.ttf") format("truetype"), url("../../../fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Medium.eot");
  src: url("../../../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Medium.woff2") format("woff2"), url("../../../fonts/Roboto-Medium.woff") format("woff"), url("../../../fonts/Roboto-Medium.ttf") format("truetype"), url("../../../fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Bold.eot");
  src: url("../../../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Bold.woff2") format("woff2"), url("../../../fonts/Roboto-Bold.woff") format("woff"), url("../../../fonts/Roboto-Bold.ttf") format("truetype"), url("../../../fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Black.eot");
  src: url("../../../fonts/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Roboto-Black.woff2") format("woff2"), url("../../../fonts/Roboto-Black.woff") format("woff"), url("../../../fonts/Roboto-Black.ttf") format("truetype"), url("../../../fonts/Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
:root {
  --black: #232323;
  --white: #ffffff;
  --gray: #636363;
  --light-gray: #b3b3b3;
  --beige: #efece8;
  --light-beige: #faf7f3;
  --oil: #6a6b59;
  --dark-oil: #3c3d32;
  --light-brown: #e3dbcf;
  --red: #c75959;
  --blue: #527bb9;
  --background-color: var(--white);
  --primary-color: #000000;
  --secondary-color: #86867f;
  --input-border-color: #dddddd;
  --input-border-color-focus: #747474;
  --input-placeholder-color: #747474;
  --footer-background-color: #eaeaea;
  --promo-bar-background-color: #eaeaea;
  --primary-font: "Futura Now";
  --secondary-font: "Legan";
  --header-1: clamp(3.2rem, 2.2917vw, 4.4rem);
  --header-2: clamp(2.4rem, 1.4583vw, 2.8rem);
  --header-3: 1.5rem;
  --header-4: 1.4rem;
  --banner-title: clamp(3rem, 4.1667vw, 8rem);
  --hero-title: clamp(3rem, 2.6042vw, 5rem);
  --text: 1.5rem;
  --text-small: 1.2rem;
  --text-large: 1.8rem;
  --btn-font: 1.4rem;
  --input-font: 1.5rem;
  --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);
  --input-border-radius: 0px;
  --button-border-radius: 0px;
  --side-padding: clamp(10px, 3.9063vw, 75px);
  --side-padding-header: clamp(10px, 3.9063vw, 75px);
  --side-padding-footer: clamp(10px, 3.9063vw, 75px);
  --side-padding-checkout: clamp(30px, 4.1667vw, 80px);
}

/*========================================================*/
/* Colors */
/*========================================================*/
/*========================================================*/
/* Fonts */
/*========================================================*/
/*========================================================*/
/* Layout */
/*========================================================*/
/*========================================================*/
/* Breakpoints */
/*========================================================*/
/*========================================================*/
/* Responsive Variables */
/*========================================================*/
@media (max-width: 1280px) {
  :root {
    --text: 1.4rem;
  }
}
@media (max-width: 1024px) {
  :root {
    --side-padding-footer: 15px;
  }
}
@media (max-width: 660px) {
  :root {
    --input-font: 1.4rem;
  }
}
@media (max-width: 421px) {
  :root {
    --side-padding: 10px;
  }
}

/*========================================================*/
/* Design Specs */
/*========================================================*/
.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  font-weight: 300;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 900;
}

.primary {
  color: var(--primary-color);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.gray {
  color: var(--gray);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.header-1 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-1);
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 2;
  margin: 0 0 15px;
}

.header-2 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-2);
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1.5;
  margin: 0 0 15px;
}

.header-3 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-3);
  font-weight: 500;
  letter-spacing: 0.025em;
  line-height: 1.2;
  margin: 0 0 15px;
}

.header-4 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-4);
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1.2;
  margin: 0 0 15px;
}

.hero-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--hero-title);
  font-weight: 600;
  color: inherit;
  line-height: 1.2;
  margin: 0 auto 20px;
}

.banner-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--banner-title);
  font-weight: 600;
  line-height: 1.2;
  margin: 0 auto 20px;
}

p,
.text {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text);
  line-height: 1.8;
  margin: 0 0 10px;
}

.text-small {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text-small);
  line-height: 1.8;
  margin: 0 0 10px;
}

.text-large {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text-large);
  line-height: 1.8;
  margin: 0 0 10px;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
select,
label.control-label,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
  font-size: var(--input-font);
  font-weight: 400;
  color: var(--primary-color);
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
select,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control {
  border: 1px solid var(--input-border-color);
  padding: 15px;
  height: unset;
  line-height: 1.5;
  background-color: transparent;
  box-shadow: none;
  border-radius: var(--input-border-radius);
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=tel]::placeholder,
input[type=email]::placeholder,
textarea::placeholder,
select::placeholder,
input[type=text].form-control::placeholder,
input[type=password].form-control::placeholder,
input[type=tel].form-control::placeholder,
input[type=email].form-control::placeholder,
textarea.form-control::placeholder,
select.form-control::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 300;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=email]:focus,
textarea:focus,
select:focus,
input[type=text].form-control:focus,
input[type=password].form-control:focus,
input[type=tel].form-control:focus,
input[type=email].form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  border: 1px solid var(--input-border-color-focus);
  -webkit-box-shadow: none;
  box-shadow: none;
}
input[type=text]:-webkit-autofill, input[type=text]:-webkit-autofill:hover, input[type=text]:-webkit-autofill:focus,
input[type=password]:-webkit-autofill,
input[type=password]:-webkit-autofill:hover,
input[type=password]:-webkit-autofill:focus,
input[type=tel]:-webkit-autofill,
input[type=tel]:-webkit-autofill:hover,
input[type=tel]:-webkit-autofill:focus,
input[type=email]:-webkit-autofill,
input[type=email]:-webkit-autofill:hover,
input[type=email]:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[type=text].form-control:-webkit-autofill,
input[type=text].form-control:-webkit-autofill:hover,
input[type=text].form-control:-webkit-autofill:focus,
input[type=password].form-control:-webkit-autofill,
input[type=password].form-control:-webkit-autofill:hover,
input[type=password].form-control:-webkit-autofill:focus,
input[type=tel].form-control:-webkit-autofill,
input[type=tel].form-control:-webkit-autofill:hover,
input[type=tel].form-control:-webkit-autofill:focus,
input[type=email].form-control:-webkit-autofill,
input[type=email].form-control:-webkit-autofill:hover,
input[type=email].form-control:-webkit-autofill:focus,
textarea.form-control:-webkit-autofill,
textarea.form-control:-webkit-autofill:hover,
textarea.form-control:-webkit-autofill:focus,
select.form-control:-webkit-autofill,
select.form-control:-webkit-autofill:hover,
select.form-control:-webkit-autofill:focus {
  border: 1px solid var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
  box-shadow: 0 0 0px 1000px var(--input-border-color) inset;
}

.form-group.required .control-label:after {
  content: " *";
  color: red;
}

.modal-open {
  overflow: hidden;
}

.modal-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--modal-title);
  font-weight: 600;
}

.modal-body {
  padding: clamp(15px, 2.0833vw, 40px);
}
.modal-body h2,
.modal-body h2,
.modal-body h3,
.modal-body h4,
.modal-body h5,
.modal-body h6 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-1);
  font-weight: 500;
}

.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

/*========================================================*/
/* Reset */
/*========================================================*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

::selection {
  background: var(--black);
  color: var(--white);
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text);
  color: var(--black);
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}
@media (max-width: 991px) {
  body.cart-is-open {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: unset;
  color: var(--black);
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

b,
strong {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

img,
picture {
  display: block;
  max-width: 100%;
  border: none;
}

svg {
  display: inline-block;
}

.espa-container {
  position: fixed;
  bottom: 5px;
  left: 5px;
  z-index: 999999;
}

.espa-image {
  width: 344px;
  height: auto;
}

/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.float-right {
  float: right;
}

.side-padding {
  padding-right: var(--side-padding);
  padding-left: var(--side-padding);
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.p0 {
  padding: 0;
}

.mobile-account-items {
  display: none;
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default, #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm [type=button] {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: var(--btn-font);
  font-weight: 500;
  letter-spacing: 0.05em;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: var(--button-border-radius);
  background-image: none;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.8;
  transition: ease 0.3s;
  min-height: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-default:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button:hover, #quickcheckoutconfirm [type=button]:hover {
  color: var(--black);
  background-color: transparent;
  border-color: var(--black);
}
.btn-default.reverse, #quickcheckoutconfirm .popup-login .login-fields--wrap button.reverse, #quickcheckoutconfirm .reverse[type=button] {
  color: var(--white);
  background-color: var(--black);
}
.btn-default.reverse:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button.reverse:hover, #quickcheckoutconfirm .reverse[type=button]:hover {
  background-color: transparent;
  color: var(--black);
}
.btn-default.active, #quickcheckoutconfirm .popup-login .login-fields--wrap button.active, #quickcheckoutconfirm .active[type=button] {
  color: var(--white);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn-default.active:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button.active:hover, #quickcheckoutconfirm .active[type=button]:hover {
  background-color: transparent;
  color: var(--black);
}
.btn-default.medium-button, #quickcheckoutconfirm .popup-login .login-fields--wrap button.medium-button, #quickcheckoutconfirm .medium-button[type=button] {
  max-width: 250px;
}

.alert {
  margin: 15px;
}

.alert-success {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
  font-size: var(--text);
  border-radius: 0;
  text-align: center;
}

.alert {
  margin: 15px;
}

.btn-primary,
.btn-info {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}
.btn-primary:hover,
.btn-info:hover {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}

.slider-handle {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  justify-content: space-between;
  margin: auto;
  pointer-events: none;
  max-height: 30.4166666667vw;
}
.slider-handle > div > span {
  display: block;
  width: max(15px, 1.4583333333vw);
}
.slider-handle.recommended {
  top: 5.2083333333vw;
  width: 30.2083333333vw;
}
@media screen and (max-width: 991px) {
  .slider-handle.recommended {
    width: vw-rs(991px, 686px);
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.recommended {
    width: vw-rs(375px, 307px);
  }
}
.slider-handle.sub-recommended, .slider-handle.best-sellers, .slider-handle.special-offers {
  top: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .slider-handle.sub-recommended, .slider-handle.best-sellers, .slider-handle.special-offers {
    top: -35px;
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.prev-collection {
  top: 0;
}
@media screen and (max-width: 767px) {
  .slider-handle.prev-collection--cover-flow {
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.single-post {
  top: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .slider-handle.single-post--cover-flow {
    width: 65%;
    filter: invert(1);
  }
}
.slider-handle.shop-the-look {
  top: 5.2083333333vw;
  width: 33.3333333333vw;
}
@media screen and (max-width: 991px) {
  .slider-handle.shop-the-look {
    width: vw-rs(991px, 686px);
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.shop-the-look {
    width: vw-rs(375px, 307px);
  }
}
.slider-handle.social {
  top: 0;
  width: 100%;
}
.slider-handle.social .homepage__social__item-prev {
  right: 3.3854166667vw;
}
.slider-handle.social .homepage__social__item-next {
  left: 3.3854166667vw;
}
.slider-handle > * {
  cursor: pointer;
  pointer-events: all;
}
@media screen and (min-width: 992px) {
  .slider-handle > *:first-child {
    padding-left: 0;
  }
  .slider-handle > *:last-child {
    padding-right: 0;
  }
}
.slider-handle.store-handles {
  top: 0;
  width: 100%;
  width: 106%;
  left: -3%;
}
@media screen and (max-width: 1440px) {
  .slider-handle.store-handles {
    width: 107%;
    left: -3.5%;
  }
}
@media screen and (max-width: 1024px) {
  .slider-handle.store-handles {
    width: 110%;
    left: -5%;
  }
}
@media screen and (max-width: 767px) {
  .slider-handle.store-handles {
    width: 110%;
    left: -5%;
  }
  .slider-handle.store-handles > *:first-child {
    padding-left: 0;
  }
  .slider-handle.store-handles > *:last-child {
    padding-right: 0;
  }
}
@media screen and (max-width: 660px) {
  .slider-handle.store-handles {
    width: 120%;
    left: -10%;
  }
}

/*========================================================*/
/* Header style_m */
/*========================================================*/
#main-header {
  padding: 30px 30px 0;
  justify-content: space-between;
  align-items: center;
}
#main-header .account-items {
  flex-basis: 40%;
}
#main-header .account-items a:not(.language-switcher) {
  color: var(--primary-color);
  margin-right: 1.823vw;
  align-items: center;
}
#main-header .account-items a .text {
  display: inline-block;
  vertical-align: top;
  font-size: 1.3rem;
  text-transform: lowercase;
}
#main-header .main-logo {
  flex-basis: 20%;
  align-self: center;
}
#main-header .main-logo .logo-icon svg {
  margin: 0 auto;
  display: block;
  width: 277px;
  height: 34px;
}

.shortcuts-items {
  flex-basis: 40%;
}
.shortcuts-items #search {
  max-width: 280px;
  width: 100%;
  margin: 0 2.604vw;
}
.shortcuts-items #search .form-control {
  font-size: 1.3rem;
  border: none;
  padding-left: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--primary-color);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.shortcuts-items #search .form-control::-webkit-input-placeholder {
  line-height: 3.2;
}
.shortcuts-items #search .form-control::-moz-placeholder {
  line-height: 3.2;
}
.shortcuts-items #search .form-control:-ms-input-placeholder {
  line-height: 3.2;
}
.shortcuts-items #search .form-control:-moz-placeholder {
  line-height: 3.2;
}
.shortcuts-items #search .search-btn {
  background: transparent;
  border: none;
}
.shortcuts-items #search .search-btn .search-icon {
  display: inline-block;
  width: 17px;
  height: 19px;
  position: relative;
  top: 4px;
  z-index: 999;
}
.shortcuts-items #search .search-btn .search-icon svg {
  display: inline-block;
  width: 17px;
  height: 19px;
}
.shortcuts-items #search #d_ajax_search_results {
  position: absolute;
  top: 45px;
  z-index: 9;
  background: #fff;
  padding: 15px;
  margin-top: 0;
  border: none;
  -webkit-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .shortcuts-items #search #d_ajax_search_results {
    width: 100%;
  }
}
.shortcuts-items #search #d_ajax_search_results .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shortcuts-items #search #d_ajax_search_results .row div:first-child {
  flex-basis: 20%;
}
.shortcuts-items #search #d_ajax_search_results .row div:first-child img {
  border: none;
  background-color: transparent;
}
.shortcuts-items #search #d_ajax_search_results .row div:nth-child(2) {
  flex-basis: 60%;
}
.shortcuts-items #search #d_ajax_search_results .row div:nth-child(3) {
  flex-basis: 20%;
}
.shortcuts-items #search #d_ajax_search_results .row.result-link {
  position: relative;
  padding: 0;
  margin: 15px 0;
  color: var(--primary-color);
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 400;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.shortcuts-items #search #d_ajax_search_results .row.result-link:hover {
  background-color: var(--light-gray);
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.shortcuts-items #search #d_ajax_search_results .row.result-link:hover div:first-child img {
  mix-blend-mode: darken;
}
.shortcuts-items #search #d_ajax_search_results span.where-find {
  display: none;
}
.shortcuts-items #search #d_ajax_search_results .all_results {
  display: flex;
  width: 100%;
  clear: both;
  font-size: 1.3rem;
  line-height: initial;
  font-weight: 400;
  color: #fff;
  text-align: center;
  background-color: var(--primary-color);
  padding: 7px 15px;
}

.account-icon {
  display: inline-block;
  width: 20px;
  height: 19px;
}
.account-icon svg {
  display: inline-block;
  width: 20px;
  height: 19px;
}

.wishlist-icon {
  width: 21px;
  height: 19px;
}
.wishlist-icon svg {
  display: inline-block;
  width: 21px;
  height: 19px;
}

.language-switcher .btn-group {
  position: relative;
}
.language-switcher .btn-group button {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  text-transform: lowercase;
  text-align: right;
  padding-right: 10px;
}
.language-switcher .btn-group button .fa {
  position: absolute;
  top: 0;
  right: 0;
}
.language-switcher .btn-group .dropdown-menu {
  min-width: 35px;
  left: unset;
  right: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
}
.language-switcher .btn-group .dropdown-menu button {
  color: var(--primary-color);
}
.language-switcher .btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*cart*/
.shortcuts-items {
  justify-content: flex-end;
}
.shortcuts-items #cart {
  max-width: 50px;
  width: 100%;
  margin: 0;
  text-align: right;
  position: relative;
}
.shortcuts-items #cart .cart-btn {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 34px;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.shortcuts-items #cart .cart-btn .cart-icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 8px;
  left: 0;
  background-image: url("../../../icons/cart-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.shortcuts-items #cart .cart-btn #cart-total {
  font-size: 1rem;
  color: #fff;
  width: 20px;
  display: inline-block;
  position: absolute;
  right: -5px;
  top: 8px;
  background-color: black;
}
.shortcuts-items #cart .cart-btn #cart-total:after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: -1;
}

@media (max-width: 1024px) {
  /*header*/
  .logo-icon,
  .logo-icon svg {
    width: 240px;
    height: auto;
  }
}
@media (max-width: 991px) {
  /*search*/
  body:not(.checkout-container) {
    padding-top: 85px;
  }
  /*header*/
  #main-header {
    align-items: flex-start;
    position: fixed;
    padding: 15px;
    width: 100%;
    top: 0;
    background: white;
    z-index: 999;
    min-height: 50px;
  }
  #main-header .header-mobile-menu {
    flex-basis: 33.3333333%;
  }
  #main-header .main-logo {
    flex-basis: 33.3333333%;
  }
  #main-header .main-logo #logo {
    margin-bottom: 0;
  }
  #main-header .main-logo #logo a img {
    max-width: 148px;
    width: 100%;
    margin: 0 auto;
  }
  #main-header .alert-dismissable .close, #main-header .alert-dismissible .close {
    top: -30px;
    right: -7px;
  }
  .shortcuts-items.mobile {
    flex-basis: 33.3333333%;
  }
  .shortcuts-items.mobile #cart {
    float: right;
  }
  .shortcuts-items.mobile #cart .cart-btn {
    top: -5px;
  }
  .shortcuts-items.mobile #search {
    margin: 0 auto;
    max-width: unset;
    position: relative;
  }
  .shortcuts-items.mobile #search .input-group-btn {
    position: absolute;
    right: 30px;
    top: 5px;
  }
  .shortcuts-items.mobile #search .form-control {
    border-top: 1px solid #000;
    padding: 0 35px 0 15px;
    min-height: 45px;
  }
}
footer {
  margin-top: 40px;
  background-color: var(--footer-background-color);
  color: var(--primary-color);
}
footer .header-2 {
  margin: 0 0 30px;
}
@media (max-width: 767px) {
  footer .header-2 {
    margin-bottom: 15px;
  }
}
footer .footer-container {
  padding: clamp(30px, 3.6458vw, 70px) var(--side-padding-footer) 25px;
}
footer .footer-wrapper {
  width: 100%;
  max-width: 1540px;
  margin: 0 auto;
}
footer .footer-menus-wrapper > div {
  flex: 1;
}
@media (max-width: 1280px) {
  footer .footer-menus-wrapper > div {
    flex: unset;
    max-width: unset;
  }
  footer .footer-menus-wrapper > div:not(:last-child) {
    width: calc(33.3333% - 30px);
    margin: 0 15px;
  }
}
@media (max-width: 767px) {
  footer .footer-menus-wrapper > div:not(:last-child) {
    width: 100%;
    margin: auto;
  }
  footer .footer-menus-wrapper > div:last-child {
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  footer .footer-menus-wrapper {
    flex-wrap: wrap;
  }
  footer .footer-menus-wrapper .footer-newsletter {
    order: 1;
  }
  footer .footer-menus-wrapper .footer-menu {
    order: 2;
  }
  footer .footer-menus-wrapper .footer-information {
    order: 3;
  }
  footer .footer-menus-wrapper .footer-social {
    order: 4;
  }
  footer .footer-menus-wrapper .footer-title + .footer-links {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.2s ease, opacity 0.3s ease 0.4s;
  }
  footer .footer-menus-wrapper .footer-title.open + .footer-links {
    margin-top: 15px;
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
  }
  footer .footer-menus-wrapper .footer-title {
    position: relative;
  }
  footer .footer-menus-wrapper:not(.footer-newsletter) .footer-title {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--primary-color);
  }
  footer .footer-menus-wrapper:not(.footer-newsletter) .footer-title::after {
    content: "";
    display: block;
    width: 12px;
    height: 7px;
    background-image: url("../../../icons/arrow-down.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }
  footer .footer-menus-wrapper:not(.footer-newsletter) .footer-title.open:after {
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s ease;
  }
}
footer .footer-newsletter .newsletter-promo {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  footer .footer-newsletter .newsletter-promo {
    margin-bottom: 10px;
  }
}
footer .footer-newsletter .newsletter-wrapper {
  position: relative;
  border: 1px solid var(--primary-color);
  width: 100%;
  max-width: 400px;
}
@media (max-width: 767px) {
  footer .footer-newsletter .newsletter-wrapper {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  footer .footer-newsletter .newsletter-form {
    margin: 0 auto 25px;
    text-align: center;
  }
}
footer .footer-newsletter .newsletter-wrapper input[type=text] {
  position: relative;
  width: 100%;
  border: none;
  color: var(--primary-color);
  padding: 8px 60px 8px 20px;
  height: 46px;
}
footer .footer-newsletter .newsletter-wrapper input[type=email] {
  position: relative;
  width: 100%;
  border: none;
  color: var(--primary-color);
  padding: 8px 60px 8px 20px;
  height: 46px;
}
footer .footer-newsletter .newsletter-subscribe-btn {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: var(--primary-color);
  padding: 15px 20px;
  border: none;
}
footer .footer-newsletter .newsletter-submit-arrow svg,
footer .footer-newsletter .newsletter-submit-arrow {
  display: block;
  width: 7px;
  height: 12px;
}
footer .footer-separator {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--footer-background-color);
  filter: brightness(85%);
  margin: 40px 0;
}
@media (max-width: 991px) {
  footer .footer-separator {
    margin: 20px 0;
  }
}
footer .eight8-logo {
  display: inline-block;
  width: 100%;
  max-width: 35px;
  position: relative;
  top: 2px;
}
footer .payment-cards li {
  margin: 0 15px;
}
footer .payment-cards li:first-child {
  margin-left: 0;
}
footer .payment-cards li:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  footer .payment-cards li {
    margin: 0 10px;
  }
}
footer .payment-cards span {
  display: block;
  width: 100%;
}
footer .payment-cards span.paypal-icon {
  max-width: 70px;
}
footer .payment-cards span.visa-icon {
  max-width: 50px;
}
footer .payment-cards span.mastercard-icon {
  max-width: 35px;
}
footer .payment-cards span.gpay-icon {
  max-width: 50px;
}
footer .payment-cards span.applepay-icon {
  max-width: 50px;
}
footer .payment-cards svg {
  width: 100%;
}
footer .credits-container {
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  footer .credits-container {
    flex-direction: column-reverse;
  }
  footer .credits-container .right {
    margin: 0 0 15px;
  }
}

/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart .dropdown-menu {
  padding: 15px;
  background-color: #fff;
  border: none;
  min-width: 390px;
  right: -30px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  #cart .dropdown-menu {
    top: 0;
    right: 0;
    left: unset;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
    min-width: auto;
    width: 100vw;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
  }
}
#cart .dropdown-menu .close-mini-cart {
  justify-content: flex-end;
  margin-bottom: 30px;
}
#cart .dropdown-menu .close-mini-cart a.close-mini-cart-btn {
  cursor: pointer;
  display: inline-block;
}
#cart .dropdown-menu .close-mini-cart a.close-mini-cart-btn .close-btn-icon {
  display: inline-block;
  margin-right: 5px;
  width: 11px;
  height: 11px;
}
#cart .dropdown-menu .close-mini-cart a.close-mini-cart-btn .close-btn-icon svg {
  display: inline-block;
  width: 11px;
  height: 11px;
}
#cart .dropdown-menu .close-mini-cart a.close-mini-cart-btn .close-btn-icon svg .st0 {
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
#cart .dropdown-menu .close-mini-cart a.close-mini-cart-btn .close-btn-text {
  position: relative;
  display: inline-block;
  font-size: 1rem;
  line-height: initial;
  color: var(--primary-color);
  font-weight: 400;
  letter-spacing: 0.025em;
  top: -2px;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products {
  overflow-y: auto;
  max-height: calc(100vh - 325px);
  /* Track */
  /* Handle */
  /* Handle on hover */
}
#cart .dropdown-menu .all-products-w-button .scrollable-products::-webkit-scrollbar {
  width: 5px;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products::-webkit-scrollbar-thumb {
  background: #000;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products::-webkit-scrollbar-thumb:hover {
  background: #000;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products .text-center {
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products {
  padding-bottom: 20px;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
  flex-basis: 20%;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img a {
  display: inline-block;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img a .img-thumbnail {
  border: none;
  padding: 0;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
  flex-basis: 60%;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-name {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primary-color);
  letter-spacing: 0.025em;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-description, #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item .prd-price {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primary-color);
  letter-spacing: 0.025em;
  margin: 0;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-remove {
  flex-basis: 20%;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-remove .remove-cart-item {
  border: none;
  background-color: transparent;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
  display: block;
  width: 9px;
  height: 9px;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
  display: block;
  width: 9px;
  height: 9px;
}
#cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg .st0 {
  fill: none;
  stroke: #8c8c8c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart {
  padding-top: 20px;
}
@media (max-width: 991px) {
  #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart {
    position: absolute;
    bottom: 60px;
    width: 100%;
    left: 0;
    padding: 0 15px;
  }
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-total {
  width: 100%;
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-total .col-total {
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.022em;
  background-color: #e4e4e4;
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  padding: 25px;
  margin-bottom: 15px;
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-total .col-total:nth-child(2) {
  display: none;
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-checkout {
  width: 100%;
  background-color: var(--primary-color);
}
#cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-checkout a {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: #fff;
  text-transform: uppercase;
}

/*========================================================*/
/* Homepage style_m */
/*========================================================*/
/*home categories*/
.home-categories-container {
  margin: 75px auto;
}

.categories-wrapper.flex-row {
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.categories-wrapper .category-item {
  position: relative;
}

.categories-wrapper .category-item a {
  color: #000;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: 70px;
}

.categories-wrapper .category-item:first-child {
  margin-right: 15px;
}

.categories-wrapper .category-item:last-child {
  margin-left: 15px;
}

.categories-wrapper .category-item .category-info {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 30px 25px 25px;
  background-color: rgb(255, 255, 255);
  width: calc(100% - 8.333vw);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.categories-wrapper .category-item a:hover .category-info {
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.categories-wrapper .category-item .title {
  justify-content: space-between;
  align-items: center;
}

.categories-wrapper .category-item .title h3 {
  max-width: calc(100% - 120px);
}

.categories-wrapper .category-item .title .category-btn {
  font-size: 1.4rem;
  font-weight: 600;
}

.categories-wrapper .category-item .title .category-btn:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #000;
  position: relative;
  bottom: -3px;
}

/*home carousel tabs*/
.carousel-container {
  margin: 100px auto;
  padding: 0 30px;
}

.carousel-container .nav-tabs {
  text-align: center;
  border-bottom: none;
  margin-bottom: 30px;
}

.carousel-container .nav-tabs > li {
  float: unset;
  display: inline-block;
}

.carousel-container .nav-tabs > li a {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #000;
  border: none;
  padding: 10px 25px;
  margin: 0;
}

.carousel-container .nav-tabs > li.active a {
  font-size: 2.4rem;
  line-height: 2;
  font-weight: 600;
  color: #000;
  border: none;
}

.carousel-container .nav-tabs > li a:focus,
.carousel-container .nav-tabs > li a:hover {
  background-color: transparent;
}

.carousel-container .nav-tabs > li.active a:after {
  content: "";
  width: 100%;
  height: 3px;
  display: block;
  background-color: #000;
  position: relative;
  bottom: -3px;
}

/*carousel slick dots*/
.carousel-container .tab-content .tab-pane {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
}

.carousel-container .tab-content .tab-pane.active {
  height: auto;
}

.carousel-container .tab-content .caption {
  margin-top: 15px;
}

.carousel-container .tab-content .tab-pane .carousel-wrapper {
  position: relative;
  padding-bottom: 55px;
}

.carousel-container .slick-dots {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.carousel-container .slick-dots li {
  display: inline-block;
  margin: 0 10px;
}

.carousel-container .slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  display: inline-block;
  padding: 0;
  background-color: #c8c8c9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}

.carousel-container .slick-dots li.slick-active button {
  background-color: #000;
}

/*carousel slick arrows */
.carousel-arrows {
  display: none;
}

.show-slider-nav + .carousel-arrows {
  display: block;
}

.carousel-container .carousel-arrows {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 15px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 420px;
  width: 100%;
}

@media (max-width: 600px) {
  .carousel-container .carousel-arrows {
    max-width: 370px;
  }
}
@media (max-width: 499px) {
  .carousel-container .carousel-arrows {
    max-width: 300px;
  }
}
.carousel-container .carousel-arrows .arrow,
.carousel-container .carousel-arrows .arrow svg {
  display: block;
  width: 30px;
  height: 9px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .carousel-container .carousel-arrows .arrow,
  .carousel-container .carousel-arrows .arrow svg {
    bottom: 13px;
  }
}
@media (max-width: 509px) {
  .carousel-container .carousel-arrows .arrow,
  .carousel-container .carousel-arrows .arrow svg {
    bottom: 24px;
  }
}
@media (max-width: 389px) {
  .carousel-container .carousel-arrows .arrow,
  .carousel-container .carousel-arrows .arrow svg {
    bottom: 33px;
  }
}
.carousel-container .carousel-arrows .arrow {
  position: absolute;
}

.carousel-container .carousel-arrows .arrow.next {
  right: 0;
}

/*home more categories*/
.more-category-wrapper.flex-row {
  justify-content: center;
}

.home-more-categories {
  padding: 0 15px;
  padding-bottom: 40px;
}

.home-more-categories h2 {
  margin-bottom: 50px;
}

.home-more-categories .category-item:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.more-category-wrapper .category-item {
  position: relative;
  margin: 0 15px;
}

.more-category-wrapper .category-info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 25px 30px;
  width: 100%;
  z-index: 1;
}

.more-category-btn {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  padding: 15px 25px;
  position: relative;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.category-item a:hover .more-category-btn {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.more-category-wrapper .category-item img {
  align-self: center;
}

.slider-carousel-slick {
  position: relative;
  padding: 0 40px;
}
.slider-carousel-slick .slider-arrows {
  margin-top: -8px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider-carousel-slick .slider-arrows .arrow {
  width: 10px;
  height: 15px;
  position: absolute;
  cursor: pointer;
}
.slider-carousel-slick .slider-arrows .arrow.arrow-prev {
  left: 15px;
}
.slider-carousel-slick .slider-arrows .arrow.arrow-next {
  right: 15px;
}

@media (max-width: 1366px) {
  /*homepage*/
  .more-category-wrapper .category-info {
    padding: 20px;
  }
  .more-category-wrapper .category-info h3 {
    font-size: 2.5rem;
  }
  .more-category-btn {
    padding: 10px 20px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  /*homepage*/
  .home-categories-container {
    margin: 25px auto 75px;
  }
  .categories-wrapper .category-item .category-info {
    width: calc(100% - 3.333vw);
    padding: 20px;
  }
  .more-category-wrapper.flex-row {
    flex-wrap: wrap;
  }
  .more-category-wrapper .category-item {
    margin-bottom: 30px;
    width: calc(50% - 30px);
  }
  .more-category-wrapper .category-item a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  /*homepage*/
  .home-categories-container {
    margin-bottom: 55px;
  }
  .categories-wrapper .category-item .title h3 {
    font-size: 1.5rem;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  /*homepage*/
  .categories-wrapper.flex-row {
    flex-direction: column;
    padding: 0 15px;
  }
  .home-categories-container {
    margin-bottom: 35px;
  }
  .categories-wrapper .category-item:first-child,
  .categories-wrapper .category-item:last-child {
    margin: 0 auto;
  }
  .categories-wrapper .category-item + .category-item {
    margin-top: 15px;
  }
  .categories-wrapper .category-item .category-info {
    width: calc(100% - 15vw);
    padding: 10px;
    text-align: center;
  }
  .categories-wrapper .category-item .title h3 {
    margin: 5px auto;
  }
  .categories-wrapper .category-item .title {
    flex-direction: column;
  }
  .home-more-categories {
    padding-bottom: 0;
  }
  .home-more-categories h2 {
    font-size: 2rem;
  }
  .more-category-wrapper .category-item {
    width: calc(50% - 30px);
    margin: 0 auto 15px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 1.6rem;
    padding: 15px;
  }
}
@media (max-width: 660px) {
  /*homepage*/
  .carousel-container {
    margin-top: 20px;
    padding: 0 15px;
  }
  .carousel-container .nav-tabs {
    margin-bottom: 20px;
  }
  .carousel-container .nav-tabs > li a {
    font-size: 1.5rem;
    padding: 10px;
  }
  .carousel-container .nav-tabs > li.active a {
    font-size: 1.6rem;
  }
}
.article-image > a {
  display: block;
  max-height: 210px;
  overflow: hidden;
}

.more-desc {
  background: #fff;
  padding: 30px 20px;
  margin-top: 0px;
  float: left;
  width: 100%;
}

span.date-added {
  font-size: 14px;
  color: #555;
  font-weight: 300;
}

.article-author {
  text-decoration: none;
  font-weight: bold;
}

.article-author a {
  text-decoration: none;
  font-weight: bold;
}

.article-author h4 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
  color: #353291;
  margin-top: 8px;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.home-posts {
  padding-top: 70px;
  padding-bottom: 50px;
}
.home-posts h3.panel-title {
  margin-bottom: 45px;
  font-size: 2.4rem;
  line-height: 2;
  font-weight: 600;
  color: #000000;
}
.home-posts .description {
  height: 55px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home-posts a.ola-ta-blog {
  display: block;
  margin: 40px auto 0;
  border: 1px solid #000000;
  color: #000000;
  padding: 10px;
  text-align: center;
  width: fit-content;
}
.home-posts a.ola-ta-blog:hover {
  background-color: #000000;
  color: #f2f2f2;
}

@media (max-width: 767px) {
  .article-image img {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .homepage-blog .text-right {
    margin-bottom: 20px;
  }
}

h3.panel-title img {
  width: 44px;
  margin-left: 14px;
  position: relative;
  top: -3px;
  display: unset;
}

h3.panel-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 22px;
  color: #444;
}

.homepage__social {
  margin-top: max(80px, 5.7291666667vw);
  margin-bottom: 5.7291666667vw;
  margin-left: 3.3854166667vw;
  margin-right: 3.3854166667vw;
}
@media (max-width: 767px) {
  .homepage__social {
    margin-top: 5.7291666667vw;
  }
}
.homepage__social--title {
  text-align: center;
  margin-bottom: max(30px, 3.3854166667vw);
  font-family: var(--font-default), sans-serif;
  font-size: 3.4rem;
  font-weight: 100;
  font-style: normal;
  line-height: 1.2;
}
@media (max-width: 767px) {
  .homepage__social--title {
    font-size: 2.4rem;
  }
}
.homepage__social__item {
  width: 18.2291666667vw !important;
  height: 23.4375vw !important;
  margin-right: 1.5625vw !important;
}
.homepage__social__item a {
  display: flex;
  width: 100%;
  height: 100%;
}
.homepage__social__item a img {
  object-fit: cover;
  height: 100%;
}
.homepage__social__item .social-view a img {
  width: 26.40625vw;
  height: 26.40625vw;
  object-fit: cover;
}
.homepage__social__item:hover:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.homepage__social .slider-wrap {
  display: none;
  position: relative !important;
}

.arrow-right-span {
  font-size: 25px;
  font-weight: bold;
  padding: 3px;
  padding-left: 5px;
}

.arrow-left-span {
  font-size: 25px;
  font-weight: bold;
  padding: 3px;
}

@media (max-width: 660px) {
  .arrow-right-span {
    font-size: 13px;
    font-weight: normal;
  }
  .arrow-left-span {
    font-size: 13px;
    font-weight: normal;
  }
}
.popup {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup_close {
  font-size: 36px;
  color: #000;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  z-index: 1001;
}

@media (max-width: 767px) {
  .popup_close {
    top: 1px;
    right: 5px;
    font-size: 25px;
  }
}
.popup-left,
.popup-right {
  width: 100%;
}

.popup-left img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.popup-right {
  padding-top: 15px;
}

.popup-right h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.popup-right h2 {
  font-size: 20px;
  margin: 0;
}

@media (min-width: 768px) {
  .popup-content {
    flex-direction: row;
    width: 60%;
  }
  .popup-right {
    padding-left: 20px;
  }
  .popup-right h1 {
    font-size: 34px;
  }
  .popup-right h2 {
    font-size: 24px;
  }
}
/*========================================================*/
/*** LOGIN ***/
/*========================================================*/
@media (max-width: 991px) {
  #account-login {
    width: 100%;
  }
}
#account-login .breadcrumb {
  display: none;
}
#account-login #content {
  padding-top: clamp(50px, 5.2083vw, 100px);
}
#account-login .well {
  padding: 20px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#account-login .well.new-customer-form:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  #account-login .well.new-customer-form:before {
    content: none;
  }
}
@media (max-width: 767px) {
  #account-login .well {
    padding: 10px;
  }
}
#account-login .register-btn {
  max-width: 200px;
  margin: 25px auto 0;
}
#account-login .forgotten-pass {
  margin-top: 5px;
}

/*========================================================*/
/*** SOCIAL LOGIN ***/
/*========================================================*/
#d_social_login {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto clamp(30px, 2.6042vw, 50px) auto;
}
#d_social_login .d-social-login__button {
  display: block;
  width: 100%;
  color: var(--white);
  text-decoration: none;
}
#d_social_login .d-social-login__button .d-social-login__button__icon {
  max-width: 100%;
  text-align: left;
  width: 100%;
  display: block;
  position: relative;
  padding-left: 45px;
}
#d_social_login .d-social-login__button .d-social-login__button__icon img {
  max-width: 30px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
#d_social_login .d-social-login__button.d-social-login__button--facebook .d-social-login__button__icon img {
  max-width: 10px;
}
#d_social_login .d-social-login__button.d-social-login__button--google span.d-social-login__button__icon img {
  max-width: 25px;
}

/*========================================================*/
/*** REST ACCOUNT PAGES ***/
/*========================================================*/
.common-account {
  padding: clamp(50px, 5.2083vw, 100px) 15px;
  float: none;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.common-account.large-container {
  max-width: 900px;
}
.common-account .breadcrumb {
  display: none;
}
.common-account legend {
  font-size: var(--text);
  font-weight: 500;
  border-bottom: none;
  margin: 0 0 15px;
  text-transform: uppercase;
}
.common-account .terms {
  margin: 0 0 15px;
}
.common-account ul > li > a {
  line-height: 2.5;
  text-decoration: none;
}
.common-account .btn-default, .common-account #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .common-account button, .common-account #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .common-account [type=button] {
  text-decoration: none !important;
}
.common-account .col-sm-2.control-label {
  display: none;
}
.common-account .default-address,
.common-account .is-opened {
  display: block !important;
  text-align: left;
  width: 100%;
}
.common-account .buttons {
  margin-top: 15px;
}
.common-account .col-sm-10 {
  display: block;
  width: 100%;
}
.common-account .table-bordered > tbody > tr > td,
.common-account .table-bordered > tbody > tr > th,
.common-account .table-bordered > tfoot > tr > td,
.common-account .table-bordered > tfoot > tr > th,
.common-account .table-bordered > thead > tr > td,
.common-account .table-bordered > thead > tr > th {
  border: none;
  border-bottom: 1px solid var(--input-border-color);
  padding: 20px 15px;
}
.common-account td.text-left a {
  color: #000;
  text-decoration: none;
}
.common-account td.text-right > a {
  text-decoration: none;
}
.common-account .add-to-cart-icon svg,
.common-account .add-to-cart-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.common-account .remove-item-icon svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.common-account .remove-item-icon svg,
.common-account .remove-item-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.common-account thead > tr > td {
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}
.common-account tbody > tr > td {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
}
.common-account .table-hover > tbody > tr {
  opacity: 0.7;
  background-color: transparent;
}
.common-account .table-hover > tbody > tr:hover {
  opacity: 1;
}
.common-account td.text-right > a.edit-address-btn,
.common-account td.text-right > a.remove-address-btn,
.common-account td.text-right > a.btn-view-item {
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #000;
  border: none;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  text-decoration: underline;
}
.common-account.account-voucher .col-sm-2.control-label {
  display: block;
  width: 100%;
  text-align: left;
}

.container.common-account.small-container {
  max-width: 500px;
}

.input-group.date button.btn.btn-default, .input-group.date #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .input-group.date button, .input-group.date #quickcheckoutconfirm button[type=button], #quickcheckoutconfirm .input-group.date button[type=button] {
  padding: 9px 20px;
}

label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
  text-align: left;
}

.form-group .custom-field {
  display: none;
}

.checkout-container #main-header,
.checkout-container #search {
  display: none;
}
.checkout-container #d_logo {
  padding: clamp(15px, 2.6042vw, 50px) var(--side-padding-header);
}
.checkout-container #d_logo a {
  display: inline-block;
  margin: 0 auto;
}
.checkout-container .checkout-header {
  margin: 0 0 clamp(25px, 5.2083vw, 100px);
  padding: 0 var(--side-padding-header);
}
.checkout-container .header-2 {
  margin: 0;
}
.checkout-container a {
  color: var(--primary-color);
  text-decoration: underline;
}
.checkout-container .guest-login-register {
  display: flex;
}
.checkout-container .guest-login-register .btn-group {
  flex-basis: 33.333333%;
}
@media (max-width: 660px) {
  .checkout-container .guest-login-register .btn-group:nth-child(1), .checkout-container .guest-login-register .btn-group:nth-child(2) {
    flex-basis: 50%;
    margin: 0 0 5px;
  }
  .checkout-container .guest-login-register .btn-group:nth-child(3) {
    flex-basis: 100%;
  }
}
.checkout-container .checkout-left {
  border-right: 1px solid var(--input-border-color);
  padding-right: var(--side-padding-checkout);
}
@media (max-width: 991px) {
  .checkout-container .checkout-left {
    border-right: unset;
    padding-right: 0;
    padding-left: 0;
  }
}
.checkout-container .checkout-right {
  padding-left: var(--side-padding-checkout);
}
@media (max-width: 991px) {
  .checkout-container .checkout-right {
    padding-right: 0;
    padding-left: 0;
  }
}
.checkout-container #d_quickcheckout {
  padding: 0 var(--side-padding-checkout) clamp(25px, 5.2083vw, 100px);
  max-width: 1800px;
  width: 100%;
}
.checkout-container .form-horizontal .radio,
.checkout-container .form-horizontal .checkbox,
.checkout-container .form-horizontal .radio-inline,
.checkout-container .form-horizontal .checkbox-inline {
  padding-top: 0;
}
@media (max-width: 767px) {
  .checkout-container .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
.checkout-container .well {
  box-shadow: none;
}
.checkout-container #qc_confirm_order {
  padding: 20px 15px;
}
.checkout-container #qc_confirm_order:disabled {
  color: var(--input-border-color);
  background-color: var(--white);
  border-color: var(--input-border-color);
  transition: all linear 0.3s;
}
@media (max-width: 660px) {
  .checkout-container #qc_confirm_order {
    padding: 15px;
  }
}
.checkout-container .text-danger {
  margin: 0 !important;
}
.checkout-container button.decrease, .checkout-container button.increase {
  display: none;
}
.checkout-container button.delete {
  background-color: unset;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: unset;
}
.checkout-container .panel {
  border-radius: 0;
  border: none;
  background: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .checkout-container .panel {
    margin-bottom: 0;
  }
}
.checkout-container .panel .panel-body {
  padding: 15px 0;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-body {
    padding: 10px;
  }
}
.checkout-container .panel .panel-heading {
  background-color: unset;
  border-radius: 0;
  border: none;
  padding: 10px 0;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-heading {
    padding: 10px 10px 0 10px;
  }
}
.checkout-container #payment_address_heading_heading > i {
  display: none;
}
.checkout-container .btn, .checkout-container #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container button, .checkout-container #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .checkout-container [type=button] {
  box-shadow: none !important;
  text-shadow: none !important;
}
.checkout-container #login_view .guest-login-register {
  display: flex;
  flex-wrap: wrap;
}
.checkout-container #cart_view .qc-name a {
  text-decoration: none;
}
.checkout-container #cart_view .table,
.checkout-container #cart_view .table td {
  border: none;
}
@media (max-width: 660px) {
  .checkout-container #cart_view .table,
  .checkout-container #cart_view .table td {
    font-size: 1.2rem;
  }
}
.checkout-container #cart_view .table thead {
  border-bottom: 1px solid var(--input-border-color);
}
.checkout-container #cart_view .qc-coupon {
  margin: 0 0 30px;
}
.checkout-container #cart_view .qc-coupon .col-sm-12 {
  padding: 0;
}
.checkout-container #cart_view .qc-coupon #coupon,
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  height: 40px;
}
.checkout-container #cart_view .qc-coupon #coupon {
  width: 100%;
  min-height: 35px;
  padding: 5px 15px;
}
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  display: block;
  max-width: 135px;
  width: 100%;
  padding: 5px 20px;
  height: 40px;
}
.checkout-container #cart_view .qc-totals label {
  text-align: left !important;
  font-weight: 600;
  padding: 5px 0;
}
.checkout-container #cart_view .qc-totals .row {
  margin: 0;
  font-size: var(--header-3);
  text-align: right;
  border-bottom: 1px solid var(--input-border-color);
  padding: 10px 0;
}
.checkout-container #cart_view .qc-totals .row:last-child {
  border: 0;
  margin-top: 0;
}
.checkout-container #cart_view .qc-product-qantity {
  max-width: 50px;
  min-height: 35px;
  padding: 5px;
}
.checkout-container #cart_view .qc-quantity {
  width: 20%;
}
.checkout-container #cart_view .qc-checkout-product table.table tbody td {
  vertical-align: middle;
}
.checkout-container #payment_address_form,
.checkout-container #shipping_address_form {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.checkout-container #payment_address_form > div,
.checkout-container #shipping_address_form > div {
  flex-basis: calc(50% + 15px);
}
.checkout-container #payment_address_newsletter_input,
.checkout-container #payment_address_email_input,
.checkout-container #payment_address_email_confirm_input,
.checkout-container #payment_address_company_input,
.checkout-container #payment_address_address_1_input,
.checkout-container #payment_address_city_input,
.checkout-container #payment_address_postcode_input,
.checkout-container #payment_address_customer_group_id_input,
.checkout-container #payment_address_custom_field\.account\.1_input,
.checkout-container #payment_address_shipping_address_input,
.checkout-container #payment_address_agree_input,
.checkout-container #payment_address_heading_heading {
  flex-basis: calc(100% + 30px) !important;
}
.checkout-container #payment_address_customer_group_id_input .col-xs-12:first-child {
  display: none;
}
.checkout-container #confirm_form {
  margin-bottom: clamp(30px, 3.125vw, 60px);
}
.checkout-container #confirm_comment {
  min-height: 140px;
  resize: vertical;
}
.checkout-container #payment_address_newsletter_input label,
.checkout-container #payment_address_shipping_address_input label,
.checkout-container #payment_address_agree_input label,
.checkout-container #payment_address_heading_heading label {
  text-align: left;
}
.checkout-container #payment_address_heading_heading {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .checkout-container #payment_address_heading_heading {
    margin-bottom: 10px;
  }
}
.checkout-container #login_model {
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .checkout-container #payment_address_form > div,
  .checkout-container #shipping_address_form > div {
    flex-basis: calc(100% + 30px) !important;
  }
  .checkout-container #payment_address_form > div > div,
  .checkout-container #shipping_address_form > div > div,
  .checkout-container #confirm_comment_input > div,
  .checkout-container #confirm_agree_input > div {
    padding: 0;
  }
  .checkout-container #payment_view {
    padding: 10px;
  }
}

#banner-easy-returns {
  margin-bottom: 0;
}

.checkout-hidden {
  display: none !important;
}

.cart-section-title, #quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--header-2);
  font-weight: 400;
  line-height: 1.2;
  color: #333333;
}

.popup-login-title, #quickcheckoutconfirm .popup-login__head__title {
  font-family: var(--primary-font), sans-serif;
  font-weight: 500;
  font-size: 2.8rem;
}

.popup-login-close, #quickcheckoutconfirm .popup-login__head ._close {
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.popup-login-close:focus, #quickcheckoutconfirm .popup-login__head ._close:focus, .popup-login-close:hover, #quickcheckoutconfirm .popup-login__head ._close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.checkout__social--login a {
  font-size: 1.5rem;
  text-align: center;
  padding: 22px 20px;
  max-height: unset;
  background-color: var(--blue);
  color: var(--white);
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 991px) {
  .checkout__social--login a {
    font-size: 1.3rem;
    padding: 12px 10px;
  }
}

#quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  margin-bottom: 25px;
}
#quickcheckoutconfirm .checkout__title--no-top-space {
  margin-top: unset;
}
#quickcheckoutconfirm .checkout__social--login {
  background-color: var(--white);
  padding-bottom: 25px;
}
@media (max-width: 991px) {
  #quickcheckoutconfirm .checkout__social--login a {
    font-size: 1.5rem;
    padding: 22px 20px;
  }
}

#quickcheckoutconfirm {
  color: var(--black);
  max-width: 1640px;
  padding-left: max(10px, 4.1666666667vw);
  padding-right: max(10px, 4.1666666667vw);
  padding-bottom: max(30px, 2.6041666667vw);
  margin: auto;
}
#quickcheckoutconfirm .checkout__wrap {
  display: flex;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .checkout__wrap > * {
  width: 50%;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap > * {
    width: 100%;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side {
  background-color: var(--white);
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--left-side {
    margin-right: 2.34375vw;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--left-side {
    background-color: var(--white);
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap {
    padding-bottom: 60px;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .shipping > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .payment > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > #terms > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .address > *:not(.address__type) {
  padding-left: 25px;
  padding-right: 25px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .shipping > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .payment > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > #terms > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .address > *:not(.address__type) {
    padding-left: 0;
    padding-right: 0;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap .payment__wrap {
  margin-top: 15px;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    margin-left: 2.34375vw;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side__content--wrap {
    padding-bottom: 60px;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type {
  display: flex;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type > * {
  padding: 15px;
  cursor: pointer;
  flex: 1;
  background-color: var(--white);
  color: var(--black);
  font-family: var(--primary-font), sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 20px 25px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type > * {
    border-bottom: 1px solid var(--light-gray);
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type--active {
  background-color: var(--white);
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type--active {
    background-color: var(--white);
    text-decoration: unset;
    font-weight: bold;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__customer-type--wrap {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .checkout__wrap .terms__wrap {
  background-color: red;
}
#quickcheckoutconfirm .checkout__wrap .terms a {
  color: var(--primary-color);
}
#quickcheckoutconfirm .checkout__wrap .payment__wrap:before,
#quickcheckoutconfirm .checkout__wrap .shipping__wrap:before {
  content: "";
  width: 100%;
  display: flex;
  padding-top: 20px;
}
#quickcheckoutconfirm label {
  margin-bottom: 0;
  font-weight: initial;
}
#quickcheckoutconfirm label a {
  font-weight: bold;
  text-decoration: underline;
}
#quickcheckoutconfirm .option__wrap:not(:last-child) {
  margin-bottom: 10px;
}
#quickcheckoutconfirm .option__wrap__heading {
  display: none;
}
#quickcheckoutconfirm [type=button] {
  width: 100%;
}
#quickcheckoutconfirm [type=button]:hover {
  color: var(--black) !important;
  background-color: transparent !important;
  border-color: var(--black) !important;
}
#quickcheckoutconfirm .radio__wrap,
#quickcheckoutconfirm .checkbox__wrap {
  display: flex;
  align-items: center;
}
#quickcheckoutconfirm .radio__wrap [type=radio],
#quickcheckoutconfirm .radio__wrap [type=checkbox],
#quickcheckoutconfirm .checkbox__wrap [type=radio],
#quickcheckoutconfirm .checkbox__wrap [type=checkbox] {
  margin-right: 10px;
}
#quickcheckoutconfirm .checkbox__wrap {
  line-height: 1;
  margin-bottom: 10px;
  width: 100%;
}
#quickcheckoutconfirm .checkbox__wrap span {
  cursor: pointer;
}
#quickcheckoutconfirm .checkbox__wrap--show-passwords {
  display: none;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .checkbox__wrap--agree {
    width: 100%;
    margin-bottom: 15px !important;
    justify-content: center;
  }
}
#quickcheckoutconfirm .radio__wrap span,
#quickcheckoutconfirm .radio__wrap input {
  cursor: pointer;
}
#quickcheckoutconfirm .field__wrap {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .field__wrap--input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: calc(50% - max(30px, 1.5625vw) / 2);
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap--input {
    margin-right: 0 !important;
    width: 100%;
  }
}

.checkout__cart {
  padding-top: 15px;
  padding-bottom: 15px;
}
.checkout__cart__head,
.checkout__cart .checkout__cart__content--product {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--white);
}
.checkout__cart__head > *,
.checkout__cart .checkout__cart__content--product > * {
  width: 20%;
}
@media screen and (max-width: 600px) {
  .checkout__cart__head > *,
  .checkout__cart .checkout__cart__content--product > * {
    width: 18%;
    font-size: 1.1rem;
  }
}
.checkout__cart__head .checkout__cart--image,
.checkout__cart .checkout__cart__content--product .checkout__cart--image {
  width: 12%;
  min-width: max(60px, 4.1666666667vw);
}
.checkout__cart__head .checkout__cart--name,
.checkout__cart .checkout__cart__content--product .checkout__cart--name {
  width: 40%;
  margin-right: auto;
}
.checkout__cart__head .checkout__cart--name small,
.checkout__cart .checkout__cart__content--product .checkout__cart--name small {
  font-size: 85% !important;
}
.checkout__cart__head .checkout__cart--quantity,
.checkout__cart .checkout__cart__content--product .checkout__cart--quantity {
  text-align: center;
}
.checkout__cart__head .checkout__cart--price,
.checkout__cart .checkout__cart__content--product .checkout__cart--price {
  display: none;
}
.checkout__cart__head .checkout__cart--total,
.checkout__cart .checkout__cart__content--product .checkout__cart--total {
  text-align: right;
}
.checkout__cart__head {
  padding-top: 8px;
  padding-bottom: 18px;
}
.checkout__cart__head .checkout__cart--image {
  opacity: 0;
}
.checkout__cart__content .checkout__cart--name {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--black);
}
.checkout__cart__content .checkout__cart--name > *:first-child {
  font-family: var(--secondary-font), sans-serif;
}
.checkout__cart__content .checkout__cart--name--giftwrap {
  margin-top: 10px;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap {
  font-size: 1.2rem;
  margin-bottom: unset;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap span {
  margin-bottom: 3px;
}
.checkout__cart__output--totals {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.checkout__cart__output--totals span {
  font-size: 1.4rem;
}
.checkout__cart__output--totals span:first-child {
  font-weight: 500;
}
.checkout__cart__output--totals:first-child {
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child {
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child span {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.checkout__cart--image img {
  mix-blend-mode: multiply;
  border: none;
  width: 100%;
  max-width: 80px;
}
@media screen and (max-width: 600px) {
  .checkout__cart--name .checkbox__wrap {
    font-size: 1.1rem;
  }
}
.checkout__cart--quantity__wrap {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.checkout__cart--quantity__control {
  border: 1px solid var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout__cart--quantity__control > * {
  padding: 5px !important;
  display: flex;
  align-items: center;
  height: 33px;
}
.checkout__cart--quantity__control input {
  border: none !important;
  text-align: center;
}
.checkout__cart--quantity__control button {
  background-color: transparent;
  border: none;
}
.checkout__cart--quantity__update, .checkout__cart--quantity__remove {
  background-color: unset;
  padding: 0;
  margin: 5px;
}
.checkout__cart--quantity__update {
  margin-left: 15px;
}
.checkout__cart--quantity__remove {
  position: absolute;
  right: min(-35px, -3.125vw);
}
@media screen and (max-width: 600px) {
  .checkout__cart--quantity__remove {
    display: none;
  }
}
.checkout__cart--textarea {
  display: flex;
  width: 100%;
  min-height: 140px;
  margin-top: clamp(30px, 3.125vw, 60px);
  margin-bottom: clamp(30px, 3.125vw, 60px);
  resize: vertical;
}

#quickcheckoutconfirm .cart {
  padding-top: 20px;
}
#quickcheckoutconfirm .text-danger {
  font-size: 1.2rem;
  color: #e45451;
}

#quickcheckoutconfirm .redeem__wrap {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
#quickcheckoutconfirm .redeem--item {
  display: flex;
}
#quickcheckoutconfirm .redeem--item:not(:last-child) {
  margin-bottom: 25px;
}
#quickcheckoutconfirm .redeem--heading {
  display: none;
}
#quickcheckoutconfirm .redeem--content {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .redeem--content input {
  flex: 1;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content input {
    padding: 20px 15px;
  }
}
#quickcheckoutconfirm .redeem--content button {
  width: 100%;
  max-width: 135px;
  padding: 5px 20px;
  white-space: normal;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content button {
    max-width: unset;
    margin-top: 15px;
    padding: 20px 15px;
  }
}

#quickcheckoutconfirm .popup-login {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
#quickcheckoutconfirm .popup-login > span {
  content: "";
  background-color: rgba(34, 34, 34, 0.75);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
#quickcheckoutconfirm .popup-login.open {
  display: flex;
}
#quickcheckoutconfirm .popup-login__wrap {
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  margin: 20px;
}
#quickcheckoutconfirm .popup-login__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
#quickcheckoutconfirm .popup-login .login-fields {
  width: 100%;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap > *:not(:last-child) {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap a {
  text-decoration: underline;
}
#quickcheckoutconfirm .checkout-header {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 5.2083333333vw;
}
#quickcheckoutconfirm .checkout-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header__wrap {
    justify-content: center;
    align-items: baseline;
    padding-top: 45px;
  }
}
#quickcheckoutconfirm .checkout-header__wrap > * {
  width: 300px;
  color: var(--primary-color);
}
#quickcheckoutconfirm .checkout-header__wrap > * * {
  white-space: nowrap;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header__block {
    order: 2;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--back-link {
    margin-bottom: 5px;
  }
}
#quickcheckoutconfirm .checkout-header--logo {
  display: flex;
  flex: auto;
  justify-content: center;
}
#quickcheckoutconfirm .checkout-header--logo img {
  width: 100%;
  max-width: 320px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--logo {
    order: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    padding-top: 5px;
    z-index: 2;
  }
  #quickcheckoutconfirm .checkout-header--logo img {
    max-width: 150px;
  }
}
#quickcheckoutconfirm .checkout-header--logo svg {
  display: flex;
  width: max(100px, 12.5vw);
}
#quickcheckoutconfirm .checkout-header--assist {
  text-align: right;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout-header--assist {
    padding-right: 25px;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--assist {
    display: flex;
    flex-direction: column;
    width: auto;
    text-align: center;
  }
}
#quickcheckoutconfirm .checkout-header ._underline {
  text-decoration: underline;
}
#quickcheckoutconfirm .checkout-header .support-icon {
  position: relative;
  top: 5px;
  margin: 0 5px;
}

#quickcheckoutconfirm #d_social_login {
  display: unset;
  width: unset;
  max-width: unset;
  margin: unset;
}

#checkout-safety-net {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.address__title {
  margin-top: 40px;
}

.field__wrap--input.required .control-label:after {
  content: " *";
  color: var(--gray);
}

.page_quickcheckout .megamenu-style-dev {
  display: none;
}

/* cart page */
#checkout-cart .mini-cart-total {
  font-size: 1.4rem;
  text-align: right;
}
#checkout-cart .mini-cart-checkout {
  background-color: transparent;
  justify-content: flex-end;
}
#checkout-cart .mini-cart-checkout a {
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  align-self: flex-end;
  background-color: var(--primary-color);
  max-width: 250px;
  color: var(--white);
  border: 1px solid var(--primary-color);
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 660px) {
  #checkout-cart .mini-cart-checkout a {
    max-width: 100%;
  }
}
#checkout-cart .mini-cart-checkout a:hover {
  background-color: transparent;
  color: var(--primary-color);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#checkout-cart .cart-page .mini-cart-total {
  display: flex;
  flex-basis: 100%;
  margin-top: 30px;
  justify-content: flex-end;
}
#checkout-cart .cart-page .mini-cart-total .col-total {
  width: 100%;
  max-width: 250px;
  font-size: 1.3rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.022em;
  background-color: #e4e4e4;
  color: var(--primary-color);
  text-align: center;
  text-transform: uppercase;
  padding: 25px;
  margin-bottom: 15px;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page .mini-cart-total .col-total {
    max-width: 100%;
  }
}
#checkout-cart .cart-page .mini-cart-total .cart-breakdown {
  width: 100%;
  margin-bottom: 10px;
}
#checkout-cart .cart-page ul li {
  border-bottom: 1px solid #ddd;
}
#checkout-cart .cart-page ul li + li {
  margin-top: 10px;
}
#checkout-cart .cart-page ul li .mini-cart-products {
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img {
  flex-basis: 15%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-img {
    flex-basis: 25%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a {
  display: inline-block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-img a .img-thumbnail {
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item {
  flex-basis: 55%;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item {
    flex-basis: 70%;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
  display: inline-block;
  margin-bottom: 0;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item a {
    font-size: 1.4rem;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-description, #checkout-cart .cart-page ul li .mini-cart-products .prd-item .prd-price {
  margin-bottom: 0;
}
#checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
  display: none;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .prd-item .product-quantity.mobile-view {
    display: block;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove {
  align-self: start;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item {
  background: transparent;
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
  display: block;
  width: 17px;
  height: 17px;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg {
    width: 12px;
    height: 12px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-remove .remove-cart-item .delete-icon svg .st0 {
  fill: none;
  stroke: #8c8c8c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity {
  flex-basis: 20%;
  margin-right: 15px;
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity.desktop-view {
    display: none;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field {
  display: block;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .number-input {
  border: none;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
  display: flex;
  justify-content: space-between;
  flex-basis: 60%;
}
@media (max-width: 991px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    flex-basis: 100%;
  }
}
@media (max-width: 660px) {
  #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity {
    max-width: 165px;
    margin-top: 20px;
  }
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity button.btn.btn-primary, #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity #quickcheckoutconfirm button.btn-primary[type=button], #quickcheckoutconfirm #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity button.btn-primary[type=button], #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity #quickcheckoutconfirm .popup-login .login-fields--wrap button.btn-primary, #quickcheckoutconfirm .popup-login .login-fields--wrap #checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity button.btn-primary {
  background: no-repeat;
  color: var(--primary-color);
  border: 0;
  text-shadow: none;
  box-shadow: none;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  margin-left: 15px;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper {
  position: relative;
  width: 100%;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper input.form-control {
  border: 0;
  box-shadow: none;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  text-align: center;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;
}
#checkout-cart .cart-page ul li .mini-cart-products .product-quantity .quantity-input-field .quantity .quantity-wrapper .quantity-nav .quantity-button {
  cursor: pointer;
  padding: 8px 20px;
}
#checkout-cart .product-grid, #checkout-cart .choosegift, #checkout-cart .giftavailable {
  display: none;
}

/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.category-container {
  padding: 0 35px;
}

.breadcrumb {
  text-align: center;
  margin: 0;
  padding: 30px 0 15px 0;
  border: none;
  background-color: transparent;
}
.breadcrumb li {
  padding: 0;
}
.breadcrumb li a {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 3.7rem;
  font-weight: 400;
  letter-spacing: 0.025rem;
  color: #000000;
}
.breadcrumb li::after {
  content: "/";
  display: inline-block;
  margin: 0 7px 0 10px;
}
.breadcrumb li:last-child::after {
  display: none;
}
.breadcrumb li + li:before {
  display: none;
}

.flex-row.categories-choices {
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 55px;
}

.category-container .category-info {
  padding-bottom: 35px;
}

.products-block .product-thumb .caption {
  padding: 15px;
  min-height: 150px;
}

.products-filters {
  width: 100%;
  max-width: 200px;
  margin-right: 10px;
}

.category-grid {
  width: 100%;
  max-width: 1640px;
  margin-left: 10px;
}

.products-block {
  width: 100%;
  display: inline-block;
  margin: 0 -15px;
}

.product-col {
  width: 25%;
  display: inline-block;
  float: left;
  padding: 0 10px;
  position: relative;
}

span.add-to-wishlist-icon svg .st0 {
  fill: #bcbcbc;
  stroke: #000;
}

span.add-to-wishlist-icon.active svg .st0 {
  fill: #ff8b00;
}

span.add-to-wishlist-icon svg,
span.add-to-wishlist-icon {
  display: block;
  width: 26px;
  height: 22px;
}

.product-col:nth-child(4n+1) {
  clear: both;
}

.products-block .product-thumb {
  margin-bottom: 60px;
  cursor: pointer;
  position: relative;
  overflow: initial;
}

.product-thumb {
  border: none;
}

.category-info h2 {
  text-align: center;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.category-info .category-description {
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.category-description #hidden {
  display: none;
}
.category-description #show-more {
  margin: 15px auto 0;
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
}
.category-description #show-more:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.category-description #show-more:hover:after {
  width: 100%;
  margin-left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.category-info p {
  text-align: justify;
  font-size: 1.3rem;
  line-height: 2.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}

span.category-separator {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  margin: 25px 0 35px;
}

.total-products p {
  font-size: 1.2rem;
  line-height: 3rem;
  color: #747474;
  font-weight: 400;
  letter-spacing: 0.025em;
}

.category-short-by {
  position: relative;
  width: 100%;
  max-width: 220px;
}

.category-short-by .form-group.input-group {
  width: 100%;
  max-width: 220px;
}

.category-short-by label {
  display: none;
}

.category-short-by select#input-sort {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  font-size: 1.2rem;
  letter-spacing: 0.025em;
  -webkit-appearance: initial;
  height: unset;
  padding: 15px 25px;
}

span.sort-icon svg .st0 {
  fill: #010101;
}

span.sort-icon,
span.sort-icon svg {
  display: block;
  width: 10px;
  height: 13px;
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 9;
}

.caption > .product-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.caption ul.product-available-colors {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caption ul.product-available-colors > li {
  display: inline-block;
}

.caption ul.product-available-colors > li:not(last-child) {
  margin-right: 5px;
}

.caption ul.product-available-colors > li img {
  border-radius: 50%;
  max-width: 19px;
}

.product-thumb .price-tax {
  display: none;
}

.flex-row.category-pagination {
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #000000;
  background-color: transparent;
  border: none;
  padding: 12px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #000000;
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.025em;
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.category-pagination span.arrow-left-start {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-left-first.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-right-end {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-right-last.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-next {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-next.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.category-pagination span.arrow-prev {
  display: block;
  content: "";
  background-image: url("../../../icons/arrow-prev.svg");
  width: 12px;
  height: 12px;
  background-position: center;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.products-filters .list-group,
.products-filters .swiper-viewport,
.box.bf-check-position .box-heading {
  display: none;
}

.products-filters .box {
  border: none;
  background-color: transparent;
  border-radius: 0;
}

.products-filters .bf-form > .bf-attr-block:first-of-type .bf-attr-header,
.bf-attr-block .bf-attr-header.bf-clickable {
  font-size: 1.6rem;
  line-height: 6rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #eaeaea;
  padding: 7px 0;
}

.bf-attr-block .bf-close-btn,
.bf-attr-block .bf-arrow {
  position: absolute;
  top: 40%;
  right: 0;
  -webkit-transform: translateX(-40%);
  -moz-transform: translateX(-40%);
  -o-transform: translateX(-40%);
  transform: translateX(-40%);
}

.bf-cell {
  border-bottom: none !important;
}

.bf-attr-block-cont {
  padding: 0;
}

.bf-attr-filter .bf-cell:first-child {
  display: none;
}

.bf-attr-filter .bf-cell label {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
}

.bf-attr-filter .bf-cell label:after {
  display: inline-block;
  content: "";
  width: 0;
  background-color: #000000;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.bf-attr-filter .bf-cell label:hover:after {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.bf-attr-block .bf-attr-block-cont {
  padding: 10px 0;
}

.products-filters .bf-price-slider-container .bf-slider-range .ui-slider-range.ui-widget-header.ui-corner-all {
  background: #000000;
}

.products-filters .bf-buttonclear-box {
  border: 1px solid #000;
  background-color: #000;
  margin-top: 30px;
}

.products-filters .bf-buttonclear {
  background: none !important;
  background-repeat: no-repeat;
  text-decoration: none;
  padding-left: 0;
  line-height: initial;
  color: transparent;
}

.products-filters .bf-buttonclear:hover {
  background: none !important;
}

.products-filters input[type=reset] {
  text-align: center;
  color: #fff;
  background-color: transparent;
  border-color: #000;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.products-filters .bf-slider-container.ui-slider-horizontal,
.products-filters .bf-slider-range.ui-slider-horizontal {
  height: 3px;
}

.products-filters .ui-slider-horizontal .ui-slider-handle {
  top: -7px;
}

.products-filters span.ui-slider-handle.ui-state-default.ui-corner-all {
  left: 0;
  background: #fff !important;
  width: auto !important;
  height: 22px !important;
  border: 1px solid #000 !important;
  border-radius: 8px !important;
  text-align: center;
  padding: 0 4px;
  font-size: 1.1rem;
  top: -11px;
}

.products-filters span.ui-slider-handle.ui-state-default.ui-corner-all:last-child {
  -webkit-transform: translate(-69%, 0);
  -moz-transform: translate(-69%, 0);
  -o-transform: translate(-69%, 0);
  transform: translate(-69%, 0);
}

.bf-price-container.box-content.bf-attr-filter > .bf-cur-symb {
  display: none;
}

.products-filters .bf-cur-symb {
  padding-bottom: 20px;
}

.products-filters input[type=text] {
  border: 1px solid #000000;
}

.product-thumb .price-old {
  color: #000000;
  margin-left: 0;
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: line-through;
}

.product-thumb .price-new {
  font-weight: 800;
  color: #A66815;
  margin-left: 5px;
}

.product-thumb span.discount-percentage {
  font-weight: 400;
  color: #747474;
  margin-left: 5px;
}

.product-thumb .image {
  position: relative;
  padding: 0 10px;
}

.product-thumb .image a.has-thumb {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

.product-thumb:hover .image a.has-thumb {
  visibility: visible;
  opacity: 1;
}

.sub-categories {
  width: 100%;
  max-width: 1530px;
  display: inline-block;
  margin: 0 -15px;
}

.sub-categories .sub-category-column {
  width: 20%;
  display: inline-block;
  float: left;
  padding: 0 10px;
  position: relative;
  margin-bottom: 10px;
}

h4.sub-category-name {
  display: inline-block;
  position: relative;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 3.6rem;
}

h4.sub-category-name:after {
  display: inline-block;
  content: "";
  width: 0;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 8px;
  left: 0;
}
@media (max-width: 767px) {
  h4.sub-category-name:after {
    display: none;
  }
}

.sub-categories .sub-category-item {
  width: 100%;
  max-width: 255px;
  text-align: center;
}

.sub-categories .sub-category-item:hover h4.sub-category-name:after {
  width: 100%;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.sub-categories .sub-category-item:hover img {
  opacity: 0.8;
}

.badge {
  position: absolute;
  top: 15px;
  left: 25px;
  width: 50px;
  height: 50px;
  border: 1px solid #ff8b00;
  border-radius: 50%;
  background-color: transparent;
}

span.sale_badge {
  background-color: #ff8b00;
}

.badge i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-style: normal;
  font-size: 1.3rem;
  color: #000;
  letter-spacing: 0.025em;
  margin-top: -1px;
}

span.sale_badge i {
  color: #000;
}

.bf-cell.bf-cascade-1 {
  background-image: none;
}

@media (max-width: 1366px) {
  /*category*/
  .product-col {
    width: 33.333%;
  }
  .product-col:nth-child(3n+1) {
    clear: both;
  }
  .product-col:nth-child(4n+1) {
    clear: none;
  }
}
@media (max-width: 1140px) {
  .sub-categories .sub-category-column {
    width: 25%;
  }
}
@media (max-width: 991px) {
  /*category*/
  .caption > h4,
  h4.sub-category-name {
    line-height: 1.5;
  }
  .product-col {
    width: 50%;
  }
  .product-col:nth-child(3n+1) {
    clear: none;
  }
  .product-col:nth-child(2n+1) {
    clear: both;
  }
}
@media (max-width: 767px) {
  /*category*/
  .breadcrumb {
    padding: 10px 0;
  }
  .category-container {
    padding: 0 15px;
  }
  .products-block {
    margin: 0 auto;
  }
  .caption > h4 {
    font-size: 1.4rem;
  }
  .category-grid {
    margin-left: 0;
  }
  .sub-categories {
    margin: 0 auto;
  }
  .sub-categories .sub-category-column:nth-child(4n+1) {
    clear: both;
  }
  h4.sub-category-name {
    font-size: 1.2rem;
  }
  span.category-separator {
    margin: 15px 0;
  }
  /*filters*/
  .filters-btn {
    color: #000;
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    width: calc(50% - 15px);
    border-bottom: 1px solid #000;
    padding-bottom: 9px;
    max-height: 35px;
  }
  .filters-btn:hover,
  .filters-btn:active,
  .filters-btn:focus {
    color: #000;
  }
  .filter-icon,
  .filter-icon svg {
    display: inline-block;
    width: 12px;
    height: 13px;
  }
  .filter-icon svg .st0 {
    fill: #000;
  }
  .filters-btn .text {
    display: inline-block;
    padding-left: 5px;
  }
  .close-filters {
    position: fixed;
    top: 0;
    right: 0;
    height: 22px;
    z-index: 99;
    background-color: #fff;
    width: 100%;
  }
  .close-filters-icon,
  .close-filters-icon svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 5px;
    float: right;
  }
  .close-filters .filters-icon svg .st0 {
    fill: #000;
  }
  .category-short-by {
    width: calc(50% - 15px);
    max-width: unset;
  }
  .category-short-by .form-group.input-group {
    max-width: unset;
  }
  body.category-filters-open {
    overflow: hidden;
  }
  .products-filters.leftSidebar {
    position: fixed;
    top: 0;
    left: -100%;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    max-width: unset;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    -webkit-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    -moz-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    -o-transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
    transition: left 0.4s ease, background-color 0.1s ease, visibility 0s ease 0.4s, opacity 0s ease 0.4s;
  }
  .products-filters.leftSidebar.active {
    left: 0;
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    -moz-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    -o-transition: left 0.3s ease, background-color 0.3s ease 0.2s;
    transition: left 0.3s ease, background-color 0.3s ease 0.2s;
  }
  #column-left {
    max-width: 50%;
    padding: 0 20px;
    height: 100vh;
    background-color: #fff;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .products-filters .bf-panel-wrapper {
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding-top: 10px;
  }
  .products-filters .bf-buttonclear-box {
    position: fixed;
    bottom: 15px;
    left: 15px;
    width: calc(100% - 30px);
    z-index: 9;
  }
  .products-filters .bf-form > .bf-attr-block:first-of-type .bf-attr-header,
  .bf-attr-block .bf-attr-header.bf-clickable {
    font-size: 1.4rem;
    line-height: 2;
  }
  .products-filters .bf-cross,
  .products-filters .bf-close-btn,
  .products-filters .bf-arrow {
    background-image: url(../../../image/brainyfilter/sprite.png) !important;
  }
}
@media (max-width: 660px) {
  /*category*/
  .products-filters.leftSidebar {
    max-width: unset;
    margin-right: 0;
  }
  #column-left {
    max-width: unset;
  }
  span.sale_badge i,
  span.new_arrival_badge.badge i {
    font-size: 1.2rem;
  }
  .badge {
    top: 10px;
    left: 15px;
    width: 35px;
    height: 35px;
  }
  .product-thumb a.add-to-wishlist {
    top: 20px;
    right: 18px;
  }
  .product-thumb .add-to-cart {
    right: 18px;
  }
  .product-thumb .image {
    padding: 0 5px;
  }
}
@media (max-width: 421px) {
  /*category*/
  span.sale_badge i,
  span.new_arrival_badge.badge i {
    font-size: 1.1rem;
  }
  .badge {
    top: 5px;
    left: 10px;
    width: 30px;
    height: 30px;
  }
  .product-thumb a.add-to-wishlist {
    top: 10px;
    right: 10px;
  }
  .product-thumb .add-to-cart {
    right: 18px;
    bottom: 10px;
    width: 13px;
    height: 13px;
  }
}
@media (max-width: 375px) {
  /*category*/
  .product-thumb .add-to-cart {
    right: 15px;
  }
}
ul.pagination {
  display: none;
}

.load-more a {
  background: none;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 0;
  box-sizing: border-box;
  color: #2d2d2d;
  cursor: pointer;
  /* font-family: futura-pt,sans-serif; */
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2px;
  line-height: normal;
  margin: 0;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.results-info {
  color: #000;
  margin-bottom: 20px;
  text-align: center;
}

.basel-spinner:after {
  content: "";
  display: inline-block;
  border: 2px solid #cccccc;
  border-top: 2px solid #333333;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 500ms linear infinite;
}

.category-pagination {
  border-top: none;
  padding: 50px 0 100px;
}

.basel-spinner.light:after {
  border: 2px solid #cccccc;
  border-top: 2px solid #ffffff;
}

.basel-spinner.ajax-call:after {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -18px auto auto -18px;
}

.basel-spinner.ajax-call {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  display: inline-block;
  background: transparent;
  border-radius: 3px;
  margin: -40px auto auto -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.caption-flex-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
}

ul.brand-anchor {
  padding-bottom: 30px;
}

ul.brand-anchor li {
  max-width: 30px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 7px 7px 0;
  background-color: #f0f0f0;
}

ul.brand-anchor li > a {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  padding: 7px 0;
}

ul.brand-anchor li:hover {
  background-color: #000;
}

ul.brand-anchor li:hover a {
  color: #fff;
}

.brand-letter {
  display: inline-block;
  width: 100%;
  padding: 0 0 20px;
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #eee;
}

.letter_only {
  display: inline-block;
  float: left;
  width: 10%;
}

.brands_per_letter {
  display: inline-block;
  float: left;
  width: 90%;
}

#product-manufacturer .brand-name {
  margin: 0 0 20px 0;
  font-weight: 800;
}

.brands_per_letter a {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

/*========================================================*/
/* Product Page */
/*========================================================*/
.product-container {
  padding: 0 30px;
}

.product-row .left-col {
  max-width: 1255px;
  width: 100%;
  padding-right: 25px;
}

.product-row .right-col {
  max-width: 540px;
  width: 100%;
  padding: 0 25px;
}

/*Product images*/
.product-thumbnails {
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-thumbnails .thumb {
  flex-basis: 50%;
  margin-bottom: 30px;
}

.single-image.product-thumbnails .thumb {
  flex-basis: 100%;
}

.single-image.product-thumbnails .thumb img {
  margin: 0 auto;
}

.product-thumbnails .thumb:nth-child(odd) {
  padding-right: 15px;
}

.product-thumbnails .thumb:nth-child(even) {
  padding-left: 15px;
}

.product-row .slider-arrows {
  display: none;
}

/*Product details*/
.product-header {
  justify-content: space-between;
  align-items: center;
}

.product-header h1 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.035rem;
  margin: 10px 0;
  padding-right: 10px;
}

.wishlist-btn {
  background-color: transparent;
  border: none;
}

.wishlist-fill-icon {
  width: 100%;
  display: flex;
  margin-top: 24px;
}
.wishlist-fill-icon svg {
  display: block;
  width: 21px;
  margin-left: 15px;
}
.wishlist-fill-icon svg .st0 {
  fill: #bcbcbc;
  stroke: #000;
  stroke-width: 1px;
}
.wishlist-fill-icon.active svg .st0 {
  fill: #ff8b00;
  stroke: #000;
}

.price-list {
  margin: 30px auto;
}

.price-list li {
  display: inline-block;
  padding-right: 15px;
}

.product-price {
  font-size: 1.8rem;
  font-weight: 600;
}

.product-price.special {
  color: #ff8b00;
}

.product-price.old {
  font-size: 1.2rem;
  font-weight: 400;
  text-decoration: line-through;
}

.product-available-colors {
  margin-bottom: 20px;
}

.product-available-colors .color {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 600;
}

.product-available-colors .flex-row {
  flex-wrap: wrap;
}

.product-available-colors .flex-row li {
  max-width: 60px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.product-available-colors .flex-row li.selected {
  border: 1px solid #000;
}

.product-info .table {
  margin-bottom: 0;
}

.product-info .table > thead > tr > td,
.product-info .table > tbody > tr > td {
  padding-left: 0;
}

.select-options .control-label {
  font-size: 1.2rem;
  font-weight: 600;
}

.quantity-input-field {
  display: none;
}

.quantity-input-field.show {
  display: block;
}

.quantity-input-field .number-input {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.quantity-input-field .number-input .control-label {
  flex-basis: 10%;
  border: none;
  margin-bottom: 0;
  padding: 12px 15px;
  border-left: none;
  border-right: none;
}

.quantity-input-field .quantity {
  flex-basis: 90%;
}

.quantity-input-field .form-control {
  border-left: none;
}

#product .btn-default, #product #quickcheckoutconfirm [type=button], #quickcheckoutconfirm #product [type=button], #product #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap #product button {
  width: 100%;
}

.select-options .form-control {
  border-radius: 0;
  height: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  border-color: #000;
  -webkit-appearance: none;
}

.select-options select.form-control {
  cursor: pointer;
  background-image: url("../../../icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 98% 50%;
}

.select-options .form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.select-options .quantity {
  position: relative;
}

.select-options .quantity input[type=text] {
  padding-right: 30px;
  text-align: right;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}

.select-options .quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 25px;
}

.select-options .quantity-nav.flex-column {
  align-items: center;
  justify-content: space-evenly;
}

.select-options .quantity-nav .quantity-button {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

/*product info*/
.product-info.panel-group .panel {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  border-top: 1px solid #d6d6d6;
}

.product-info.panel-group .panel:last-child {
  border-bottom: 1px solid #d6d6d6;
}

.panel-group .panel + .panel {
  margin: 0;
}

.product-info .panel-heading {
  color: #000;
  padding: 25px 5px;
  background-color: transparent;
}

.product-info .panel-title > a {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}

.product-info .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
  padding-top: 0;
}

.product-info .panel-body {
  padding-left: 5px;
}

.product-info .panel-body * {
  font-size: 1.3rem;
  letter-spacing: 0.035rem;
}

/*related products*/
.related-products {
  position: relative;
  border-top: 1px solid #e4e4e4;
  margin-top: 120px;
  padding-top: 100px;
}
.related-products .slick-slider {
  padding-bottom: 55px;
}

.related-products h3 {
  margin: 30px auto;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.related-products .slick-slide {
  height: auto;
}

.related-products.carousel-container .carousel-arrows {
  bottom: 15px;
}

/*combine-with*/
.related-products.combine-with {
  border-top: none;
  margin-top: 0;
  margin-bottom: 120px;
}

/*add to cart message*/
@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}
.popup-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 50px;
  margin-bottom: 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: note 6500ms forwards;
}

.popup-alert a,
.popup-alert a:hover {
  color: #000;
  font-weight: 500;
}

.product-msg {
  margin-bottom: 30px;
  display: inline-block;
  margin-right: 10px;
}
.product-msg img {
  display: inline-block;
  margin-right: 10px;
}

.apothema img {
  width: 22px;
}

.apothema {
  color: #068300;
}

.prosfora img {
  width: 30px;
}

.free-delivery-msg img {
  width: 24px;
  position: relative;
  top: -2px;
}

@media (max-width: 1280px) {
  /*checkout page*/
  .checkout-container.container {
    width: 100%;
  }
  .navbar {
    padding: 15px;
  }
  .checkout-logo span.logo-icon svg,
  .checkout-logo span.logo-icon {
    width: 220px;
    height: auto;
  }
}
@media (max-width: 1024px) {
  /*product*/
  .carousel-container {
    margin: 50px auto;
  }
  .related-products {
    padding-top: 50px;
  }
  .related-products.combine-with {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  /*product*/
  .product-container {
    padding: 0 15px;
  }
  .product-row.flex-row {
    flex-direction: column;
  }
  .product-row .left-col {
    padding-right: 0;
  }
  .product-row .right-col {
    padding: 0;
    max-width: unset;
  }
  .product-thumbnails .thumb {
    margin-bottom: 0;
  }
  .product-thumbnails .thumb:nth-child(even),
  .product-thumbnails .thumb:nth-child(odd) {
    padding: 0;
  }
  .product-thumbnails .slick-slide img {
    margin: 0 auto;
  }
  .product-row .slider-arrows {
    display: block;
    top: 0;
    height: 100%;
    -webkit-transform: unset;
    -moz-transform: unset;
    -o-transform: unset;
    transform: unset;
    position: absolute;
    width: 100%;
  }
  .product-row .slider-arrows .arrow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70px;
    position: absolute;
  }
  .product-row .slider-arrows .arrow.arrow-prev {
    left: 0;
  }
  .product-row .slider-arrows .arrow.arrow-next {
    right: 0;
  }
  .product-row .slider-arrows .arrow svg {
    fill: #000;
    display: inline-block;
    width: 22px;
    height: 41px;
  }
  .products-block .product-thumb {
    margin-bottom: 15px;
  }
  .flex-row.category-pagination {
    padding: 15px 0;
  }
  .popup-alert {
    padding: 15px;
    width: 90%;
  }
}
.price-plakaki-list li {
  display: block;
}

.related-products .carousel-arrows {
  display: none;
}

.page_product input[type=text] {
  border: 1px solid #000000;
}
.page_product .select-options {
  margin-bottom: 24px;
}
.page_product .select-options .metatropi {
  margin-top: 10px;
}
.page_product .related-products .caption {
  margin-top: 15px;
  padding: 0 10px;
}
.page_product .related-products .caption h4 {
  font-size: var(--header-3);
}
.page_product .related-products h3 {
  margin: 0 0 60px;
  font-size: 2.6rem;
}

/*========================================================*/
/*** CONTACT ***/
/*========================================================*/
#information-contact .breadcrumb {
  display: none;
}

#information-contact #content {
  padding-bottom: 70px;
}

#information-contact h1 {
  text-align: center;
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 35px 0 30px;
}

#information-contact h3 {
  font-size: 1.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  text-transform: uppercase;
}

#information-contact form h3 {
  padding-bottom: 20px;
}

ul.contact-info > li,
ul.contact-info > li > a {
  font-size: 1.3rem;
  line-height: 2.4rem;
  letter-spacing: 0.025em;
  font-weight: 500;
  color: #6a6a6a;
}

ul.contact-info > li > a.email-address {
  color: #000;
  text-decoration: underline;
}

#information-contact .stores-info {
  padding-top: 50px;
}

#information-contact .addresses {
  padding-bottom: 40px;
}

.contact-info h2 {
  font-size: 25px;
}

#information-contact .hours-title {
  font-weight: 600;
}

#information-contact .working-hours {
  margin-bottom: 20px;
}
#information-contact .working-hours .row {
  max-width: 450px;
}
#information-contact .working-hours .row .text-left,
#information-contact .working-hours .row .text-right {
  width: 50%;
  float: left;
}

/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#result_block p.pull-right.block-text.text-left,
#result_block p.block-text.text-left {
  display: none;
}

.search-container {
  display: block;
  width: 100%;
  padding: 80px 35px 100px 35px;
}

.search-container .breadcrumb {
  display: none;
}

.flex-row.search-checkboxes {
  margin-top: 20px;
}

.search-checkbox-item:first-child {
  margin-right: 20px;
}

.search-container input#button-search {
  margin: 35px 0;
}

@media (max-width: 767px) {
  /*search results*/
  .search-container {
    padding: 50px 15px 0;
  }
  #input-search {
    margin-bottom: 15px;
  }
  .flex-row.search-checkboxes {
    flex-direction: column;
  }
  #button-search {
    max-width: unset;
  }
}
/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.medium-container {
  float: none;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

#information-information .breadcrumb {
  display: none;
}

#information-information #content {
  text-align: justify;
  padding-bottom: 70px;
}

#information-information div,
#information-information span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px;
}

#information-information h1,
#information-information h1 span {
  font-family: Mplus, sans-serif !important;
  text-align: center;
  font-size: 2.4rem !important;
  line-height: initial !important;
  letter-spacing: 0.025em !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 35px 0 30px !important;
}

#information-information h2,
#information-information h2 span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.4rem !important;
  letter-spacing: 0.025em !important;
  line-height: initial !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 17px 0 15px !important;
  text-transform: uppercase !important;
}

#information-information p,
#information-information ul,
#information-information ol {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px !important;
}

#information-information li,
#information-information li span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 !important;
}

#information-information a,
#information-information a span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400;
  color: #000 !important;
  text-decoration: underline;
  margin: 0 0 10px !important;
}

#information-information img {
  max-width: 100%;
}

.jtline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.jtline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: "mobile";
  display: none;
}

.jtline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}

.jtline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.jtline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px !important;
  overflow: hidden;
}

.jtline .events-wrapper::after, .jtline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 5px;
}

.jtline .events-wrapper::before {
  left: 0;
}

.jtline .events-wrapper::after {
  right: 0;
}

.jtline .events-content li {
  background: transparent;
  text-align: center;
}

figcaption {
  margin-top: 15px;
}

.jtline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 3px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}

.jtline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.jtline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  background: transparent;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-decoration: none !important;
}

.jtline .events a::after {
  /* this is used to create the event spot */
  content: "";
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .jtline .events a:hover::after {
  background-color: #000;
  border-color: #000;
}

.jtline .events a.selected {
  pointer-events: none;
  font-weight: bold !important;
}

.jtline .events a.selected::after {
  background-color: #000;
  border-color: #000;
}

.jtline .events a.older-event::after {
  border-color: #000;
}

@media only screen and (min-width: 1100px) {
  .jtline {
    margin: 6em auto;
  }
  .jtline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: "desktop";
  }
}
.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
  /* arrow icon */
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url("../../../../../../../image/cd-arrow.svg") no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
  right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
  border-color: #000;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.jtline .events-content {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}

.jtline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.jtline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.jtline .events-content li.enter-right, .jtline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}

.timeline-pic {
  display: inline;
  margin-top: 10px;
}

.jtline .events-content li.enter-left, .jtline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}

.jtline .events-content li.leave-right, .jtline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}

.jtline .events-content li > * {
  margin: 0 auto;
}

.jtline .events-content p {
  font-size: 1.4rem;
  color: #959595;
}

.jtline .events-content h2, .jtline .events-content em {
  display: none;
}

@media only screen and (min-width: 768px) {
  .jtline .events-content h2 {
    font-size: 7rem;
  }
  .jtline .events-content em {
    font-size: 2rem;
  }
  .jtline .events-content p {
    font-size: 1.8rem;
  }
}
@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
/*========================================================*/
/*** MENU style_d ***/
/*========================================================*/
#mega_menu .mobile-enabled a, #mega_menu .mobile-enabled h3 {
  font-family: var(--primary-font), sans-serif !important;
}
#mega_menu .mobile-enabled.container {
  padding-bottom: 40px;
}
#mega_menu ul.megamenu li .sub-menu .content {
  padding: 30px 0 0 !important;
}
#mega_menu ul.megamenu li .sub-menu .content .hover-menu .menu ul li ul {
  margin: 0;
  padding: 0 !important;
  font-size: 1.3rem;
  line-height: 3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
}
#mega_menu ul.megamenu li a {
  font-family: var(--primary-font), sans-serif;
  font-size: 1.7rem;
  line-height: 3.6rem;
  letter-spacing: 0.025em;
  font-weight: 400;
}
#mega_menu ul.megamenu.slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
#mega_menu ul.megamenu.slide li.active .sub-menu .container .content {
  border: none;
  padding: 50px 35px 40px 35px !important;
}
#mega_menu .main_link h3 {
  border-bottom: none;
  padding: 0;
  font-size: 1.6rem;
  line-height: 3.6rem;
  letter-spacing: 0.025em;
}
#mega_menu #menu {
  margin-bottom: 0;
}
#mega_menu .menu .subtitle a {
  font-weight: 600 !important;
}
#mega_menu .sub-menu-wrapper .menu-banner {
  text-align: center;
}
#mega_menu .sub-menu-wrapper .menu-banner .subtitle a {
  font-weight: 600 !important;
}
#mega_menu .sub-menu-wrapper .menu-banner h3 {
  font-size: 1.6rem;
  line-height: 3.6rem;
  margin-bottom: 15px;
  letter-spacing: 0.025em;
}
#mega_menu .sub-menu-wrapper ul.list-unstyled.subcategory li.subtitle2:hover .newhover a {
  font-weight: 600 !important;
}
#mega_menu .container-sub-menu {
  width: 100%;
}
#mega_menu i.fa.fonticon {
  display: none;
}

a.menutitle {
  position: relative;
  padding: 0;
}
a.menutitle::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

li.with-sub-menu.hover.active a.menutitle::after {
  width: 100%;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a {
  position: relative;
}

ul.list-unstyled.subcategory li.subtitle2 .newhover a::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 3px;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

ul.list-unstyled.subcategory li.subtitle2:hover .newhover a::after {
  width: 100%;
}

.sub-menu-banner {
  position: relative;
  transition: linear 0.3s;
}
.sub-menu-banner:hover {
  opacity: 0.9;
  transition: linear 0.3s;
}
.sub-menu-banner img {
  position: relative;
}
.sub-menu-banner a.shop-now-btn {
  position: absolute;
  bottom: 10px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 3rem;
  font-weight: 600;
  letter-spacing: 0.025em;
}

.megamenu-style-dev.responsive {
  margin-bottom: 0;
}

.megamenu-style-dev .navbar-default {
  background-color: transparent;
  border-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper {
  background-color: transparent;
}
.megamenu-style-dev .megamenu-wrapper ul.megamenu {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
.megamenu-wrapper ul.megamenu > li > a {
  font-size: 1.7rem;
  text-transform: unset;
  padding: 25px;
}

.megamenu-wrapper ul.megamenu > li > a .caret {
  display: none;
}

.megamenu-wrapper ul.megamenu > li:hover,
.megamenu-wrapper ul.megamenu > li > a:hover,
.megamenu-wrapper ul.megamenu > li.active > a,
.megamenu-wrapper ul.megamenu > li.home > a,
.megamenu-wrapper ul.megamenu > li:hover > a {
  background-color: transparent;
  color: var(--primary-color);
}

.megamenu-style-dev .container-megamenu.horizontal ul.megamenu > li > a:before {
  content: none;
}

/*submenu*/
.megamenu-wrapper ul.megamenu > li > .sub-menu {
  left: 0;
  padding-top: 35px;
}

.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu {
  background-color: #fff;
}

.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
  max-width: 1580px;
  padding: 0 20px;
  margin: 0 auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.megamenu-wrapper .megamenu .sub-menu .content > .row > div {
  width: 20%;
}

.megamenu-wrapper ul.megamenu .title-submenu:before {
  content: none;
}

.megamenu-wrapper ul.megamenu .title-submenu {
  margin-bottom: 0;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 15px;
}

.megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu a.main-menu {
  font-size: 1.4rem;
  padding: 0;
  width: auto;
  display: inline-block;
  position: relative;
}

ul.megamenu li .sub-menu .content .static-menu > .menu > ul > li {
  padding-top: 20px;
}

.megamenu-wrapper ul.megamenu .title-submenu {
  display: inline-block;
  width: auto;
  padding-bottom: 0;
  margin-bottom: 10px;
}

.megamenu-wrapper .menu-banner .title-submenu {
  position: relative;
}

.megamenu-wrapper .categories li ul li a:hover,
.megamenu-wrapper .subcategory li ul li a:hover {
  margin-left: 0;
}

.megamenu-wrapper .subcategory .title-submenu:after,
.megamenu-wrapper .categories li ul li a:after,
.megamenu-wrapper .subcategory li ul li a:after,
.megamenu-wrapper .manufacturer li a:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: var(--primary-color);
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.megamenu-wrapper .subcategory .title-submenu:hover:after,
.megamenu-wrapper .categories li ul li a:hover:after,
.megamenu-wrapper .subcategory li ul li a:hover:after,
.megamenu-wrapper .manufacturer li a:hover:after {
  width: 100%;
  margin-left: 0;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.megamenu-wrapper .menu-banner-link {
  position: relative;
}

.megamenu-wrapper .menu-banner-link .text {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.megamenu-wrapper ul.megamenu .menu-banner .title-submenu {
  text-align: center;
  width: 100%;
}

.megamenu-wrapper .menu-banner a {
  display: inline-block;
}

.megamenu-wrapper .menu-banner-link .text {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
}

/*submenu*/
.megamenu-wrapper ul.megamenu > li > .sub-menu {
  left: 0;
  padding-top: 35px;
}

.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu {
  background-color: #fff;
}

.container-megamenu .megamenu-wrapper ul.megamenu li .sub-menu .content {
  max-width: 1580px;
  padding: 0 30px;
  margin: 0 auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu .menu ul {
  margin-top: 10px;
}
.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu .menu ul li {
  padding-top: 20px;
}
.megamenu-wrapper ul.megamenu .sub-menu .content .static-menu a.main-menu {
  font-size: 1.4rem;
  padding: 0;
}
.megamenu-wrapper ul.megamenu .title-submenu {
  margin-bottom: 0;
}
.megamenu-wrapper ul.megamenu .title-submenu:before {
  content: none;
}
.megamenu-wrapper .categories li ul li a, .megamenu-wrapper .subcategory li ul li a {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.megamenu-wrapper .menu-banner-link {
  position: relative;
}
.megamenu-wrapper .menu-banner-link .text {
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #fff;
}
.megamenu-wrapper .menu-banner .title-submenu {
  text-align: center;
}
.megamenu-wrapper .menu-banner a {
  display: inline-block;
}
.megamenu-wrapper .manufacturer li {
  display: block;
}
.megamenu-wrapper .manufacturer li a {
  color: var(--primary-color);
  border: 0;
  display: inline-block;
  width: auto;
  position: relative;
  padding: 0;
}

/*discount bar*/
.sub-menu .discount-bar {
  margin-top: 40px;
}

@media (max-width: 1440px) {
  /*menu*/
  .megamenu-style-dev .megamenu-wrapper {
    padding-top: 10px;
  }
  .megamenu-wrapper ul.megamenu > li > a {
    font-size: 1.5rem;
    padding: 15px 22px;
  }
  ul.megamenu .title-submenu {
    font-size: 1.5rem;
  }
  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu a.main-menu {
    font-size: 1.3rem;
  }
}
@media (max-width: 991px) {
  /*menu*/
  body.menu-is-open {
    overflow: hidden;
  }
  .body-cover {
    position: fixed;
    z-index: 99;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  body.menu-is-open .body-cover {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .megamenu-style-dev {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 15px;
  }
  .megamenu-style-dev .megamenu-pattern {
    height: calc(100vh - 51px);
    overflow-y: auto;
    background-color: var(--white);
  }
  .megamenu-style-dev .navbar-default .navbar-toggle {
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 30px;
    padding: 0;
    height: 34px;
    display: inline-block;
    padding-top: 5px;
    margin-top: 15px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background-color: #000;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(2) {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 10px;
  }
  .megamenu-style-dev .navbar-default .navbar-toggle.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: 4px;
  }
  .megamenu-style-dev .megamenu-wrapper {
    display: block;
    padding: 0;
    position: fixed;
    top: 51px;
    left: -100%;
    z-index: 999;
    height: calc(100vh - 51px);
    max-height: unset;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    -webkit-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    -moz-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    -o-transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    transition: left 0.3s ease, opacity 0.4s ease 0.4s, visibility 0.4s ease 0.4s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .megamenu-style-dev .megamenu-wrapper.so-megamenu-active {
    left: 0;
    opacity: 1;
    visibility: visible;
    overflow-y: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .megamenu-style-dev .megamenu-wrapper .megamenu {
    flex-direction: column;
  }
  .megamenu-style-dev .megamenu-wrapper .megamenu li a {
    color: #000;
  }
  /*submenu*/
  .megamenu-wrapper .megamenu .sub-menu .content > .row > div {
    width: 100%;
  }
  .megamenu-wrapper ul.megamenu > li > .sub-menu {
    padding-top: 0;
  }
  .megamenu-wrapper.so-megamenu-active ul.megamenu .title-submenu,
  .megamenu-wrapper.so-vertical-active ul.megamenu .title-submenu {
    display: inline-block;
    max-width: unset;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .megamenu-wrapper ul.megamenu .subcategory {
    margin-top: 10px;
  }
  .megamenu-wrapper .sub-menu {
    padding-bottom: 20px;
  }
  .megamenu-wrapper .sub-menu .discount-bar {
    display: none;
  }
  .megamenu-wrapper .sub-menu .content .static-menu .menu ul li {
    padding-top: 5px;
  }
  /*menu*/
  .mobile-account-items {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    max-height: 51px;
    height: 100%;
  }
  .mobile-account-items a {
    display: inline-block;
    float: left;
    width: 50%;
    background-color: #eaeaea;
    padding: 15px 15px 10px;
    text-align: center;
    color: #000;
  }
  .mobile-account-items a:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.7);
    border-right: 1px solid rgba(0, 0, 0, 0.7);
  }
  .mobile-account-items a .text {
    vertical-align: top;
  }
  .language-switcher {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    float: left;
    width: 33.333333%;
    align-items: center;
    justify-content: center;
    background: #eaeaea;
    height: 100%;
  }
  .language-switcher .dropdown-menu {
    padding: 5px 0 10px 15px;
  }
  .language-switcher span, .language-switcher button .fa {
    color: #000;
  }
  .megamenu-wrapper ul.megamenu li .sub-menu .content .static-menu .menu ul {
    margin-top: 0;
  }
  .container-megamenu.horizontal ul.megamenu > li,
  .container-megamenu.vertical ul.megamenu > li {
    border-bottom: 1px solid #f0f0f0;
  }
  /* menu mobile */
  .megamenu-wrapper ul.megamenu > li > a .caret {
    display: inline-block;
    content: "" !important;
    width: 8px;
    height: 5px;
    background-image: url("../../../icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 5px;
    border: 0;
    float: right;
    padding: 10px;
  }
  .horizontal ul.megamenu > li.with-sub-menu > a:after {
    display: none !important;
  }
  li.with-sub-menu.active-show.click .caret {
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}
@media (max-width: 660px) {
  /*menu*/
  .megamenu-style-dev .megamenu-wrapper {
    width: 85%;
  }
  .more-category-wrapper.flex-row {
    flex-direction: column;
  }
  .more-category-wrapper .category-item {
    width: 100%;
    margin: 0 auto 15px;
  }
}
/*Mega menu*/
@media (min-width: 1001px) and (max-width: 1025px) {
  #mega_menu #menu .sub-menu .container {
    max-width: 970px;
    width: 100%;
  }
}
.hero-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.hero-slider .slide-info {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: var(--white);
}
@media (max-width: 660px) {
  .hero-slider .slide-info {
    top: unset;
    transform: unset;
    bottom: 10%;
  }
}
.hero-slider .slide-info .super-hero,
.hero-slider .slide-info .hero-btn {
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.2);
}
.hero-slider .slide-info .hero-btn {
  position: relative;
}
.hero-slider .slide-info .hero-btn:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 1px;
  background-color: var(--white);
}
.hero-slider .slide-info .hero-title {
  margin: 25px 0 40px;
  background-color: rgba(0, 0, 0, 0.2);
}
.hero-slider .swiper-slide img {
  margin: 0;
  width: -webkit-fill-available;
}
.hero-slider .hero-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  max-width: 22px;
}
@media (max-width: 660px) {
  .hero-slider .hero-arrow {
    max-width: 15px;
  }
}
.hero-slider .hero-arrow.hero-button-prev {
  left: 2%;
}
.hero-slider .hero-arrow.hero-button-next {
  right: 2%;
}

.discount-bar {
  text-align: center;
  padding: 10px 15px;
  background-color: var(--promo-bar-background-color);
}