/*========================================================*/
/*** CONTACT ***/
/*========================================================*/
#information-contact .breadcrumb {
  display: none;
}
#information-contact #content {
  padding-bottom: 70px;
}
#information-contact h1 {
  text-align: center;
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: .025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 35px 0 30px;
}
#information-contact h3 {
  font-size: 1.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  text-transform: uppercase;
}
#information-contact form h3 {
  padding-bottom: 20px;
}
ul.contact-info > li,
ul.contact-info > li > a{
  font-size: 1.3rem;
  line-height: 2.4rem;
  letter-spacing: .025em;
  font-weight: 500;
  color: #6a6a6a;
}
ul.contact-info > li > a.email-address {
  color: #000;
  text-decoration: underline;
}
#information-contact .stores-info {
  padding-top: 50px;
}
#information-contact .addresses {
  padding-bottom: 40px;
}
.contact-info h2{
  font-size: 25px;
}
#information-contact .hours-title {
  font-weight: 600;
}
#information-contact .working-hours {
  margin-bottom: 20px;
  .row {
    max-width: 450px;
    .text-left,
    .text-right {
      width: 50%;
      float: left;
    }
  }
}