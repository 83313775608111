/*========================================================*/
/* Header style_m */
/*========================================================*/
#main-header {
  padding: 30px 30px 0;
  justify-content: space-between;
  align-items: center;
  .account-items {
    flex-basis: 40%;
    a {
      &:not(.language-switcher) {
        color: $primary_color;
        margin-right: 1.823vw;
        align-items: center;
      }
      .text {
        display: inline-block;
        vertical-align: top;
        font-size: 1.3rem;
        text-transform: lowercase;
      }
    }
  }
  .main-logo {
    flex-basis: 20%;
    align-self: center;
    .logo-icon {
      svg {
        margin: 0 auto;
        display: block;
        width: 277px;
        height: 34px;
      }
    }
  }
}
.shortcuts-items {
  flex-basis: 40%;
  #search {
    max-width: 280px;
    width: 100%;
    margin: 0 2.604vw;
    .form-control {
      font-size: 1.3rem;
      border: none;
      padding-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      border-bottom: 1px solid $primary_color;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      &::-webkit-input-placeholder {
        line-height: 3.2;
      }
      &::-moz-placeholder {
        line-height: 3.2;
      }
      &:-ms-input-placeholder {
        line-height: 3.2;
      }
      &:-moz-placeholder {
        line-height: 3.2;
      }
    }
    .search-btn {
      background: transparent;
      border: none;
      .search-icon {
        display: inline-block;
        width: 17px;
        height: 19px;
        position: relative;
        top: 4px;
        z-index: 999;
        svg {
          display: inline-block;
          width: 17px;
          height: 19px;
        }
      }
    }
    #d_ajax_search_results {
      position: absolute;
      top: 45px;
      z-index: 9;
      background: #fff;
      padding: 15px;
      margin-top: 0;
      border: none;
      -webkit-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 10px 7px 8px 0 rgba(0, 0, 0, 0.1);
      @media (max-width: $tablet) {
       width: 100%;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          &:first-child {
            flex-basis: 20%;
            img {
              border: none;
              background-color: transparent;
            }
          }
          &:nth-child(2){
            flex-basis: 60%;
          }
          &:nth-child(3){
            flex-basis: 20%;
          }
        }
        &.result-link{
          position: relative;
          padding: 0;
          margin: 15px 0;
          color: $primary_color;
          font-size: 1.1rem;
          line-height: initial;
          font-weight: 400;
          -webkit-transition: color .3s ease;
          -moz-transition: color .3s ease;
          -o-transition: color .3s ease;
          transition: color .3s ease;
          &:hover {
            background-color: $light-gray;
            -webkit-transition: color .3s ease;
            -moz-transition: color .3s ease;
            -o-transition: color .3s ease;
            transition: color .3s ease;
            div {
              &:first-child {
                img {
                  mix-blend-mode: darken;
                }
              }
            }
          }
        }
      }
      span.where-find {
        display: none;
      }
      .all_results {
        display: flex;
        width: 100%;
        clear: both;
        font-size: 1.3rem;
        line-height: initial;
        font-weight: 400;
        color: #fff;
        text-align: center;
        background-color: $primary_color;
        padding: 7px 15px;
      }
    }
  }
}
.account-icon {
  display: inline-block;
  width: 20px;
  height: 19px;
  svg {
    display: inline-block;
    width: 20px;
    height: 19px;
  }
}
.wishlist-icon {
  width: 21px;
  height: 19px;
  svg {
    display: inline-block;
    width: 21px;
    height: 19px;
  }
}
.language-switcher {
  .btn-group {
    position: relative;
    button {
      padding: 0;
      background-color: transparent;
      border: none;
      font-size: 1.3rem;
      text-transform: lowercase;
      text-align: right;
      padding-right: 10px;
      .fa {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .dropdown-menu {
      min-width: 35px;
      left: unset;
      right: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      button {
        color: $primary_color;
      }
    }
    &.open {
      .dropdown-toggle {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}





/*cart*/
.shortcuts-items {
  justify-content: flex-end;
  #cart {
    max-width: 50px;
    width: 100%;
    margin: 0;
    text-align: right;
    position: relative;
    .cart-btn  {
      background-color: transparent;
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: 34px;
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      .cart-icon {
        display: inline-block;
        width: 19px;
        height: 19px;
        position: absolute;
        top: 8px;
        left: 0;
        background-image: url('../../../icons/cart-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      #cart-total {
        font-size: 1rem;
        color: #fff;
        width: 20px;
        display: inline-block;
        position: absolute;
        right: -5px;
        top: 8px;
        background-color: black;
        &:after {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          background-color: $primary_color;
          border-radius: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          z-index: -1;
        }
      }
    }
  }
}

@media (max-width: $tablet-lg) {
  /*header*/
  .logo-icon,
  .logo-icon svg {
    width: 240px;
    height: auto;
  }
}
@media (max-width: $tablet) {
  /*search*/
  body:not(.checkout-container) {
    padding-top: 85px;
  }

  /*header*/
  #main-header {
    align-items: flex-start;
    position: fixed;
    padding: 15px;
    width: 100%;
    top: 0;
    background: #ffff;
    z-index: 999;
    min-height: 50px;
    .header-mobile-menu {
      flex-basis: 33.3333333%;
    }
    .main-logo {
      flex-basis: 33.3333333%;
      #logo {
        margin-bottom: 0;
        a {
          img {
            max-width: 148px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
    .alert-dismissable .close, .alert-dismissible .close {
      top: -30px;
      right: -7px;
    }
  }

  .shortcuts-items {
    &.mobile {
      flex-basis: 33.3333333%;
      #cart {
        float: right;

        .cart-btn {
          top: -5px;
        }
      }
      #search {
        margin: 0 auto;
        max-width: unset;
        position: relative;
        .input-group-btn {
          position: absolute;
          right: 30px;
          top: 5px;
        }
        .form-control {
          border-top: 1px solid #000;
          padding: 0 35px 0 15px;
          min-height: 45px;
        }
      }
    }
  }
}
