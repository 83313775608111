/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.medium-container {
  float: none;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}
#information-information .breadcrumb {
  display: none;
}
#information-information #content {
  text-align: justify;
  padding-bottom: 70px;
}
#information-information div,
#information-information span {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px;
}
#information-information h1,
#information-information h1 span{
  font-family: Mplus, sans-serif !important;
  text-align: center;
  font-size: 2.4rem !important;
  line-height: initial !important;
  letter-spacing: .025em !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 35px 0 30px !important;
}
#information-information h2,
#information-information h2 span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.4rem !important;
  letter-spacing: 0.025em !important;
  line-height: initial !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin: 0 !important;
  padding: 17px 0 15px !important;
  text-transform: uppercase !important;
}
#information-information p,
#information-information ul,
#information-information ol {
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 0 10px !important;
}
#information-information li,
#information-information li span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400 !important;
  margin: 0 !important;
}
#information-information a,
#information-information a span{
  font-family: Mplus, sans-serif !important;
  font-size: 1.3rem !important;
  line-height: 2.4rem !important;
  font-weight: 400;
  color: #000 !important;
  text-decoration: underline;
  margin: 0 0 10px !important;
}
#information-information img {
  max-width: 100%;
}



.jtline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.jtline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}
.jtline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}
.jtline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}
.jtline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px !important;
  overflow: hidden;
}
.jtline .events-wrapper::after, .jtline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 5px;
}
.jtline .events-wrapper::before {
  left: 0;
}
.jtline .events-wrapper::after {
  right: 0;
}

.jtline .events-content li{
  background: transparent;
  text-align: center;
}

figcaption{
  margin-top: 15px;
}
.jtline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 3px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.jtline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.jtline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  background: transparent;
  color: #383838;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  text-decoration: none !important;
}
.jtline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .jtline .events a:hover::after {
  background-color: #000;
  border-color: #000;
}
.jtline .events a.selected {
  pointer-events: none;
  font-weight: bold !important;
}
.jtline .events a.selected::after {
  background-color: #000;
  border-color: #000;
}
.jtline .events a.older-event::after {
  border-color: #000;
}
@media only screen and (min-width: 1100px) {
  .jtline {
    margin: 6em auto;
  }
  .jtline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  background: url('../../../../../../../image/cd-arrow.svg') no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: #000;
}
.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.jtline .events-content {
  margin:0 auto;
  position: relative;

  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.jtline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.jtline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.jtline .events-content li.enter-right, .jtline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.timeline-pic{
  display: inline;
  margin-top: 10px;
}
.jtline .events-content li.enter-left, .jtline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.jtline .events-content li.leave-right, .jtline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}
.jtline .events-content li > * {

  margin: 0 auto;
}

.jtline .events-content p {
  font-size: 1.4rem;
  color: #959595;
}
.jtline .events-content h2, .jtline .events-content em  {
  display: none;
}



@media only screen and (min-width: 768px) {
  .jtline .events-content h2 {
    font-size: 7rem;
  }
  .jtline .events-content em {
    font-size: 2rem;
  }
  .jtline .events-content p {
    font-size: 1.8rem;
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
