footer {
    margin-top: 40px;
    background-color: $footer_background_color;
    color: $primary_color;
    .header-2 {
        margin: 0 0 30px;
        @media (max-width: $tablet-sm) {
            margin-bottom: 15px;
        }
    }
    .footer-container {
        padding: clamp(30px, 3.6458vw, 70px) $side_padding_footer 25px;
    }
    .footer-wrapper {
        width: 100%;
        max-width: 1540px;
        margin: 0 auto;
    }
    .footer-menus-wrapper {
        > div {
            flex: 1;
            @media (max-width: $laptop-sm) {
                flex: unset;
                max-width: unset;
                &:not(:last-child) {
                    width: calc(33.3333% - 30px);
                    margin: 0 15px;
                }
            }
            @media (max-width: $tablet-sm) {
                &:not(:last-child) {
                    width: 100%;
                    margin: auto;
                }
                &:last-child {
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
        @media (max-width: $tablet-sm) {
            flex-wrap: wrap;
            .footer-newsletter {
                order: 1;
            }
            .footer-menu {
                order: 2;
            }
            .footer-information {
                order: 3;
            }
            .footer-social {
                order: 4;
            }
            .footer-title + .footer-links {
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                transition: max-height 0.2s ease, opacity 0.3s ease 0.4s;
            }
            .footer-title.open + .footer-links {
                margin-top: 15px;
                max-height: 500px;
                opacity: 1;
                visibility: visible;
                transition: max-height 0.6s ease, opacity 0.2s ease 0.2s;
            }
            .footer-title {
                position: relative;
            }
            &:not(.footer-newsletter) .footer-title {
                padding-bottom: 20px;
                border-bottom: 1px solid $primary_color;
                &::after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 7px;
                    background-image: url('../../../icons/arrow-down.svg');
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: transform 0.3s ease;
                }
                &.open:after {
                    transform: translateY(-50%) rotate(180deg);
                    transition: transform 0.3s ease;
                }
            }
        }
    }
    .footer-newsletter {
        .newsletter-promo {
            margin-bottom: 20px;
            @media (max-width: $tablet-sm) {
                margin-bottom: 10px;
            }
        }
        .newsletter-wrapper {
            position: relative;
            border: 1px solid $primary_color;
            width: 100%;
            max-width: 400px;
            @media (max-width: $tablet-sm) {
                margin: 0 auto;
            }
        }
        .newsletter-form {
            @media (max-width: $tablet-sm) {
                margin: 0 auto 25px;
                text-align: center;
            }
        }
        .newsletter-wrapper input[type='text'] {
            position: relative;
            width: 100%;
            border: none;
            color: $primary_color;
            padding: 8px 60px 8px 20px;
            height: 46px;
        }
        .newsletter-wrapper input[type='email'] {
            position: relative;
            width: 100%;
            border: none;
            color: $primary_color;
            padding: 8px 60px 8px 20px;
            height: 46px;
        }
        .newsletter-subscribe-btn {
            cursor: pointer;
            position: absolute;
            top: 2px;
            right: 2px;
            background-color: $primary_color;
            padding: 15px 20px;
            border: none;
        }
        .newsletter-submit-arrow svg,
        .newsletter-submit-arrow {
            display: block;
            width: 7px;
            height: 12px;
        }
    }
    .footer-separator {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $footer_background_color;
        filter: brightness(85%);
        margin: 40px 0;
        @media (max-width: $tablet) {
            margin: 20px 0;
        }
    }
    .eight8-logo {
        display: inline-block;
        width: 100%;
        max-width: 35px;
        position: relative;
        top: 2px;
    }
    .payment-cards {
        li {
            margin: 0 15px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            @media (max-width: $tablet-sm) {
                margin: 0 10px;
            }
        }
        span {
            display: block;
            width: 100%;
            &.paypal-icon {
                max-width: 70px;
            }
            &.visa-icon {
                max-width: 50px;
            }
            &.mastercard-icon {
                max-width: 35px;
            }
            &.gpay-icon {
                max-width: 50px;
            }
            &.applepay-icon {
                max-width: 50px;
            }
        }
        svg {
            width: 100%;
        }
    }
    .credits-container {
        justify-content: space-between;
        align-items: center;
        @media (max-width: $tablet-sm) {
            flex-direction: column-reverse;
            .right {
                margin: 0 0 15px;
            }
        }
    }
}
